import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ApplicationRef, ErrorHandler } from '@angular/core';
//import { removeNgStyles, createNewHosts, createInputTransfer } from '@angularclass/hmr';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { ToastModule } from 'ng2-toastr/ng2-toastr';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ScrollingModule } from '@angular/cdk/scrolling';


/*
 * Platform and Environment providers/directives/pipes
 */
//import { ENV_PROVIDERS } from './environment';
import { ROUTES } from './app.routes';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';

import { AppComponent } from './app.component'; // App is our top level component

// Containers/Routes
import {
  // Layout
  LayoutMainComponent, LayoutSingleComponent, HeaderComponent, NavComponent, FooterComponent,
  // Routes
  HomeComponent, NoContentComponent, LoginComponent,
  // Scaffolding, can be removed
  TemplateFormComponent, ReactiveFormComponent, UiComponent, InventoryComponent, /*InventoryPOListComponent,*/ UnclaimedFreightComponent, ExtraStorageComponent, ScheduleComponent,
  UserManagementComponent, InstallerManagementComponent, GuideComponent, ResetPasswordComponent, InstallerTimeOffComponent,
  //Modals
  CustomerModalComponent, ProjectModalComponent, AddInstallerModalComponent, PieceModalComponent, PurchaseOrderUpdateModalComponent, VendorModalComponent, ScheduledPickUpRequestModalComponent,
  ViewPreviouslyScheduledPickUpRequestComponent, MovePOComponent, MoveFromRTSComponent, UnclaimedFreightInquryModalComponent, CustomerRTSComponent, RTSChargesComponent
} from 'app-containers';

// Reusuable components
import {
  ErrorComponent, ButtonModalComponent, FieldComponent,
  // Sortable
  SortableTableDirective, SortableColumnComponent,
  // Ng-bootstrap modals also need to be added in this file to "entryComponents"
  LogoutModalComponent,
} from 'app-components';

// Shared services and elements
import {
  // Services
  GlobalErrorHandler, AppState, AuthService,
  // Interceptors
  AuthGuard,
  // State management
  InternalStateType, StoreMainReducer, EffectsService,
  // Pipes
  SafeHtmlPipe, CustomerProjectsPipe, ProjectPurchaseOrdersPipe, PieceTagSpansPipe, PurchaseOrderUpdatesPipe, IdToCustomerNamePipe, FormatInstallersPipe, StringInstallerFromInstallersPipe,
  FilterInstallersPipe, IdToVendorNamePipe, IdToProjectNamePipe, PurchaseOrderPiecesPipe, ShowHistoricalProjectsPipe, ShowExtraStoragePipe, SortPurchaseOrdersByVendorPipe,
  ProjectScheduledPickUpRequestPipe, ShowUnresolvedUnclaimedFreightInquiriesPipe,
} from 'app-shared';

// Application stylesheet. Import everything here
import '../styles/styles.scss';

import { UnicornService, SortService, UtilityService } from "src/app/services/";
import { CookieModule } from 'ngx-cookie';

// Application wide providers
export const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  Title,
  GlobalErrorHandler,
  AppState,
  AuthService,
  AuthGuard,
  //HttpClient,
  {// Global exception handler
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  },

  //Services
  UnicornService, SortService, UtilityService, EffectsService
];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,

    // Containers
    LayoutMainComponent, LayoutSingleComponent, HeaderComponent, NavComponent, FooterComponent, HomeComponent, NoContentComponent, LoginComponent,

    //Admin
    InventoryComponent, /*InventoryPOListComponent,*/ UnclaimedFreightComponent, ExtraStorageComponent, ScheduleComponent, UserManagementComponent, InstallerManagementComponent, GuideComponent,
    ResetPasswordComponent, InstallerTimeOffComponent,

    // Components
    ErrorComponent, ButtonModalComponent, FieldComponent, LogoutModalComponent,

    // Sortable
    SortableTableDirective, SortableColumnComponent,

    //Scaffolding, can be removed
    TemplateFormComponent, ReactiveFormComponent, UiComponent,

    // Pipes
    SafeHtmlPipe, CustomerProjectsPipe, ProjectPurchaseOrdersPipe, PieceTagSpansPipe, PurchaseOrderUpdatesPipe, IdToCustomerNamePipe, FormatInstallersPipe, StringInstallerFromInstallersPipe,
    FilterInstallersPipe, IdToVendorNamePipe, IdToProjectNamePipe, PurchaseOrderPiecesPipe, ShowHistoricalProjectsPipe, ShowExtraStoragePipe, SortPurchaseOrdersByVendorPipe,
    ProjectScheduledPickUpRequestPipe, ShowUnresolvedUnclaimedFreightInquiriesPipe,

    //Modals
    CustomerModalComponent, ProjectModalComponent, AddInstallerModalComponent, PieceModalComponent, PurchaseOrderUpdateModalComponent, VendorModalComponent, ScheduledPickUpRequestModalComponent,
    ViewPreviouslyScheduledPickUpRequestComponent, MovePOComponent, MoveFromRTSComponent, UnclaimedFreightInquryModalComponent, CustomerRTSComponent, RTSChargesComponent
  ],
  imports: [
    BrowserModule,
    ScrollingModule,
    //ToastModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, { useHash: true, preloadingStrategy: PreloadAllModules }),
    StoreModule.forRoot({ mainState: StoreMainReducer }),// Inject stores here
    NgbModule.forRoot(),// Bootstrap
    AngularFontAwesomeModule,
    CookieModule.forRoot(),
  ],
  providers: [
    APP_PROVIDERS
  ],
  entryComponents: [
    LogoutModalComponent,
    CustomerModalComponent, ProjectModalComponent, AddInstallerModalComponent, PieceModalComponent, PurchaseOrderUpdateModalComponent, VendorModalComponent, ScheduledPickUpRequestModalComponent,
    ViewPreviouslyScheduledPickUpRequestComponent, MovePOComponent, MoveFromRTSComponent, UnclaimedFreightInquryModalComponent
  ]
})
export class AppModule {

}
