import { Pipe, PipeTransform } from '@angular/core';

import { IVendor } from '../../contracts/ud';

@Pipe({
    name: 'idToVendorName',
    pure: false
})
export class IdToVendorNamePipe implements PipeTransform {
    transform(vendorId: number, allVendors: IVendor[]) {
        for (var i = 0; i < allVendors.length; i++) {
            if (allVendors[i].id == vendorId)
                return allVendors[i].name;
        }
    }
}
