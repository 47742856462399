import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ICustomer } from "src/app/contracts";

@Component({
    selector: 'customer-modal-document',
    templateUrl: './customer.modal.component.html'
})
export class CustomerModalComponent implements OnInit, OnDestroy {

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public waiting: boolean = false;
    public error: IErrorApi;
    public data: ICustomer = {};

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

    submit() {
        this.activeModal.close(this.data);
    }

    public ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
