import { Pipe, PipeTransform } from '@angular/core';

import { IScheduledPickUpRequest } from '../../contracts/ud';

@Pipe({
    name: 'projectScheduledPickUpRequest',
    pure: false
})
export class ProjectScheduledPickUpRequestPipe implements PipeTransform {
  transform(allScheduledPickUpRequest: IScheduledPickUpRequest[], projectId?: number) {
    //return allScheduledPickUpRequest.filter(pickUp => projectName == pickUp.projectName);
    return allScheduledPickUpRequest.filter(pickUp => projectId == pickUp.projectId);
  }
}
