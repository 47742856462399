import { Pipe, PipeTransform } from '@angular/core';

import { IProject } from '../../contracts/ud';

@Pipe({
    name: 'showHistoricalProjects',
    pure: false
})
export class ShowHistoricalProjectsPipe implements PipeTransform {
  transform(projects: IProject[], showHistorical: boolean) {
    if (showHistorical)
      return projects;
    else
      return projects.filter(p => p["isHistorical"] == false);
  }
}
