import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { IInstaller, IProjectInstaller, IProject } from "src/app/contracts";
import { UnicornService } from "src/app/services";

@Component({
  selector: 'addInstaller-modal-document',
  templateUrl: './addInstaller.modal.component.html'
})
export class AddInstallerModalComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public waiting: boolean = false;
  public error: IErrorApi;
  public data: { installers: IInstaller[], project: IProject, installDays: number, numberOfInstallers: number } = { installers: [], project: {}, installDays: 0, numberOfInstallers: 0 };
  private selectedInstaller: IInstaller = {};
  public selectedProjectInstaller: IProjectInstaller = {};

  constructor(public activeModal: NgbActiveModal, private service: UnicornService) {
  }

  ngOnInit() {
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  submit() {
    this.activeModal.close();
  }

  addInstaller() {
    if (!this.data.project.projectInstallers)
      this.data.project.projectInstallers = [];

    if (!this.selectedInstaller.id) {
      alert("Please select an installer.");
      return;
    }

    if (1 > this.selectedProjectInstaller.daysOnJob || !this.selectedProjectInstaller.daysOnJob) {
      alert("You must have a positive number of install days.");
      return;
    }

    if (this.data.project.projectInstallers && (this.data.project.projectInstallers.length >= this.data.numberOfInstallers || !this.data.numberOfInstallers)) {
      alert("Max number of installers have already been added.");
      return;
    }

    if (this.data.installDays < this.selectedProjectInstaller.daysOnJob || !this.data.installDays) {
      alert("Cannot assign more days than the number of install days.");
      return;
    }

    this.selectedProjectInstaller.secondItemId = this.selectedInstaller.id;
    this.selectedProjectInstaller.firstItemId = this.data.project.id;

    this.service.ProjectInstaller.post(this.selectedProjectInstaller).subscribe(res => {
      this.data.project.projectInstallers.push(Object.assign({}, res));
      this.selectedProjectInstaller = {};
      this.selectedInstaller = {};
    });
  }

  removeInstaller(projectInstallerIndex: number) {
    this.service.ProjectInstaller.delete(this.data.project.projectInstallers[projectInstallerIndex]).subscribe(res => {
      this.data.project.projectInstallers.splice(projectInstallerIndex, 1);
    });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
