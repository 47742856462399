import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { IScheduledPickUpRequest } from "src/app/contracts";

@Component({
  selector: 'scheduledPickUpRequest-modal-document',
  templateUrl: './scheduledPickUpRequest.modal.component.html'
})
export class ScheduledPickUpRequestModalComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public waiting: boolean = false;
  public error: IErrorApi;
  public data: IScheduledPickUpRequest = {};

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.data.estimatedReadyDate = new Date(this.data.estimatedReadyDate);
    this.data["estimatedReadyDateDisplay"] = {};
    this.data["estimatedReadyDateDisplay"]["year"] = new Date(this.data.estimatedReadyDate).getFullYear();
    this.data["estimatedReadyDateDisplay"]["month"] = new Date(this.data.estimatedReadyDate).getMonth() + 1;
    this.data["estimatedReadyDateDisplay"]["day"] = new Date(this.data.estimatedReadyDate).getDate();
  }

  submit() {
    this.activeModal.close(this.data);
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updatereadyDate() {
    if (!this.data.estimatedReadyDate)
      this.data.estimatedReadyDate = new Date();
    this.data.estimatedReadyDate.setFullYear(this.data["estimatedReadyDateDisplay"]["year"]);
    this.data.estimatedReadyDate.setMonth(this.data["estimatedReadyDateDisplay"]["month"] - 1);
    this.data.estimatedReadyDate.setDate(this.data["estimatedReadyDateDisplay"]["day"]);
  }
}
