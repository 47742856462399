import { Pipe, PipeTransform } from '@angular/core';

import { IInstaller, IProjectInstaller } from '../../contracts/ud';

@Pipe({
    name: 'filterInstallers',
    pure: false
})
export class FilterInstallersPipe implements PipeTransform {
    transform(allInstallers: IInstaller[], projectInstallers: IProjectInstaller[]) {
        let notUsedInstallers: IInstaller[] = allInstallers;

        if (projectInstallers) {
          projectInstallers.forEach(pi => {
            notUsedInstallers = notUsedInstallers.filter(i => i.id != pi.secondItemId);
          });
        }
        return notUsedInstallers;
    }
}
