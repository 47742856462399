import { Pipe, PipeTransform } from '@angular/core';

import { ITagSpan } from '../../contracts/ud';

@Pipe({
    name: 'pieceOrderTagSpans',
    pure: false
})
export class PieceTagSpansPipe implements PipeTransform {
    transform(allTagSpans: ITagSpan[], pieceId?: number) {
        //if (!allTagSpans || allTagSpans.length < 1 || !pieceId)
        //    return;
        return allTagSpans.filter(tagSpan => pieceId == tagSpan.pieceId);
  }
}
