import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IRootStore, ICustomer, IProject, IPurchaseOrder, IVendor, IPurchaseOrderUpdate, ITagSpan, IPiece, IScheduledPickUpRequest, IUnclaimedFreightInqury } from '../../contracts/ud';
import { Services, EffectsService } from "src/app/shared";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VendorModalComponent } from "src/app/containers";
import { UtilityService } from '../../services';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'inventory',
  templateUrl: './inventory.component.html',
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)',
    '(document:keyup)': 'handleKeyboardEvents($event)'
  }
})
export class InventoryComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  private keysPressed: {} = {};
  public poAdded: boolean = false;
  public showLoadList: boolean = false;
  public selectedProjectChanging: boolean = false;
  public showFullList: boolean = false;

  selectedCustomer: ICustomer = { id: 0 };
  selectedProject: IProject = { id: 0 };
  projectDefaultTagColor: string = "";
  projectDefaultTagLot: string = "";
  loadList = [];

  subscriptions: Subscription[] = [];

  customers: ICustomer[] = [];
  projects: IProject[] = [];
  vendors: IVendor[] = [];
  purchaseOrders: IPurchaseOrder[] = [];
  purchaseOrdersForProjectFiltered: IPurchaseOrder[] = [];
  purchaseOrdersForProject: IPurchaseOrder[] = [];
  purchaseOrderUpdates: IPurchaseOrderUpdate[] = [];
  pieces: IPiece[] = [];
  scheduledPickUpRequests: IScheduledPickUpRequest[] = [];
  public customers$: Observable<ICustomer[]>;
  public projects$: Observable<IProject[]>;
  public vendors$: Observable<IVendor[]>;
  public purchaseOrders$: Observable<IPurchaseOrder[]>;
  public purchaseOrderUpdates$: Observable<IPurchaseOrderUpdate[]>;
  public pieces$: Observable<IPiece[]>;
  public scheduledPickUpRequest$: Observable<IScheduledPickUpRequest[]>;

  public filter: string = "";

  constructor(private store: Store<IRootStore>, private modalService: NgbModal, private effectService: EffectsService, public utilityService: UtilityService) {
    this.customers$ = this.store.select(state => state.mainState.customers);
    this.projects$ = this.store.select(state => state.mainState.projects);
    this.vendors$ = this.store.select(state => state.mainState.vendors);
    this.purchaseOrders$ = this.store.select(state => state.mainState.purchaseOrders);
    this.purchaseOrderUpdates$ = this.store.select(state => state.mainState.purchaseOrderUpdates);
    this.pieces$ = this.store.select(state => state.mainState.pieces);
    this.scheduledPickUpRequest$ = this.store.select(state => state.mainState.scheduledPickUpRequests);
  }

  ngOnInit() {
    this.effectService.getAll({ name: Services.Customer });
    this.effectService.getAll({ name: Services.Project });
    this.effectService.getAll({ name: Services.Vendor });
    if (this.utilityService.currentUserRole() != 'Admin' && this.utilityService.currentUserRole() != 'Employee')
      this.effectService.getAll({ name: Services.ScheduledPickUpRequest });

    this.subscriptions.push(this.customers$.subscribe(customers => {
      this.customers = customers.sort((a, b) => { return this.utilityService.compare(a, b, 'name') });
      if (customers && customers.length == 1)
        this.selectedCustomer = customers[0];
    }));

    this.subscriptions.push(this.projects$.subscribe(ps => { this.projects = ps.sort((a, b) => { return this.utilityService.compare(a, b, 'name') }); }));
    this.subscriptions.push(this.purchaseOrders$.subscribe(pos => {
      if (this.selectedProject.isResidential) {
        pos.forEach(x => {
          if (!x.deliveredOutDate)
            return;

          x.deliveredOutDate = new Date(x.deliveredOutDate);
          x["deliveredOutDateDisplay"] = {};
          x["deliveredOutDateDisplay"]["year"] = new Date(x.deliveredOutDate).getFullYear();
          x["deliveredOutDateDisplay"]["month"] = new Date(x.deliveredOutDate).getMonth() + 1;
          x["deliveredOutDateDisplay"]["day"] = new Date(x.deliveredOutDate).getDate();
        });
      }

      this.purchaseOrders = pos;

      if (this.selectedProject) {
        this.purchaseOrdersForProject = pos.filter(x => x.projectId == this.selectedProject.id);

        this.purchaseOrdersForProject.forEach(po => {
          if (po.vendorId)
            po["vendorName"] = this.vendors.filter(v => v.id == po.vendorId)[0].name;
        });

        if (this.selectedProjectChanging) {
          this.purchaseOrdersForProject = this.purchaseOrdersForProject.sort((a, b) => { return this.utilityService.compare(a, b, 'vendorName') });
          this.selectedProjectChanging = false;
        }

        //For filtering POs
        this.onFilterChange(null);

        //Scroll to end of virutal scroll
        if (this.viewport)
          this.viewport.scrollToIndex(this.purchaseOrdersForProjectFiltered.length);
      }
    }));
    this.subscriptions.push(this.purchaseOrderUpdates$.subscribe(pous => {
      this.purchaseOrderUpdates = pous;
      this.purchaseOrderUpdates.forEach(u => {
        if (u.purchaseOrderUpdateStatus == 'Completed' || u.purchaseOrderUpdateStatus == 'Released')
          u['fontColor'] = 'green';
        else if (u.purchaseOrderUpdateStatus == 'Damaged')
          u['fontColor'] = 'red';
        else if (u.purchaseOrderUpdateStatus == 'BackOrdered' || u.purchaseOrderUpdateStatus == 'WaitingForRelease')
          u['fontColor'] = 'orange';
      });
    }));
    this.subscriptions.push(this.pieces$.subscribe(ps => {
      this.pieces = ps;
      //Create Load List
      this.loadList = [];
      if (this.utilityService.userIsAdminOrEmployee()) {
        ps.forEach(p => {
          for (var i = p.tag.start; i <= p.tag.end; i++) {
            let po: IPurchaseOrder = this.purchaseOrdersForProject.filter(po => po.id == p.purchaseOrderId)[0];
            let vendorName: string = '';
            if (po && po.vendorId && this.vendors.filter(v => v.id == po.vendorId).length)
              vendorName = this.vendors.filter(v => v.id == po.vendorId)[0].name;
            let poNumber: string = '';
            if (po && po.poNumber)
              poNumber = po.poNumber;
            if (p.tag && po && !(po.deliveredOutDate && this.selectedProject.isResidential))
              this.loadList.push({ vendorName: vendorName, poNumber: poNumber, tagNumber: i + " " + p.tag.color + " " + p.tag.lot, description: p.description, tag: i, lot: p.tag.color + " " + p.tag.lot });
          }
        });
        this.loadList = this.loadList.sort((a, b) => { return this.sortTags(a, b) });
      }
    }));
    this.subscriptions.push(this.vendors$.subscribe(vs => { this.vendors = vs.sort((a, b) => { return this.utilityService.compare(a, b, 'name') }); }));
    this.subscriptions.push(this.scheduledPickUpRequest$.subscribe(spr => { this.scheduledPickUpRequests = spr; }));
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  sortTags(a: any, b: any) {
    if (a.lot < b.lot)
      return -1
    if (a.lot > b.lot)
      return 1;
    if (a.lot === b.lot) {
      if (+a.tag < +b.tag)
        return -1
      if (+a.tag > +b.tag)
        return 1;
    }
    return 0;
  }


  onFilterChange(event: any) {
    if (!this.filter && this.filter == "") {
      this.purchaseOrdersForProjectFiltered = this.purchaseOrdersForProject;
      return;
    }
    this.purchaseOrdersForProjectFiltered = this.purchaseOrdersForProject.filter(po => {
      return this.checkValue(po.notes, this.filter) || this.checkValue(po.poNumber, this.filter) || (po.vendor && this.checkValue(po.vendor.name, this.filter));
    });
  }

  checkValue(toSearch: string, filter: string): boolean {
    if (toSearch && filter && toSearch.toUpperCase().includes(filter.toUpperCase()))
      return true;

    return false;
  }

  addCustomer() {
    this.selectedCustomer = { id: 0 };
  }

  saveCustomer(customer: ICustomer) {
    if (customer.id > 0) {
      this.effectService.put({ name: Services.Customer, id: customer.id, data: customer });
    }
    else {
      this.effectService.post({ name: Services.Customer, data: customer });
    }
  }

  resetSelectedProject() {
    this.selectedProject = {};
  }

  addProject() {
    this.selectedProject = { id: 0 };
  }

  saveProject(project: IProject) {
    project.customerId = this.selectedCustomer.id;
    if (project.id > 0) {
      this.effectService.put({ name: Services.Project, id: project.id, data: project });
    }
    else {
      this.effectService.post({ name: Services.Project, data: project });
    }
  }

  cancelProject() {
    this.selectedProject = {};
  }

  public editPO(index: number) {
    console.log(index);
  }

  public saveAllPurchaseOrders() {
    this.effectService.saveAll({ name: Services.PurchaseOrder, data: this.purchaseOrders.filter(x => x.projectId == this.selectedProject.id) });
  }

  public addPO() {
    this.poAdded = true;
    this.effectService.post({ name: Services.PurchaseOrder, data: { projectId: this.selectedProject.id } });
  }

  public savePo(poId: number, newValue: any) {
    if (newValue == this.lastSaveValue || newValue == undefined)
      return;
    this.effectService.put({ name: Services.PurchaseOrder, id: poId, data: this.purchaseOrdersForProject.filter(x => x.id == poId)[0] });
  }

  public deletePO(poId: number) {
    this.effectService.delete({ name: Services.PurchaseOrder, id: poId });
  }

  savePOUpdate(purchaseOrderUpdate: IPurchaseOrderUpdate, poId?: number) {
    if (purchaseOrderUpdate["DELETE"]) {
      this.effectService.delete({ name: Services.PurchaseOrderUpdate, id: purchaseOrderUpdate.id });
    }
    else if (purchaseOrderUpdate.id > 0) {
      this.effectService.put({ name: Services.PurchaseOrderUpdate, id: purchaseOrderUpdate.id, data: purchaseOrderUpdate });
    }
    else {
      purchaseOrderUpdate.purchaseOrderId = poId;
      this.effectService.post({ name: Services.PurchaseOrderUpdate, data: purchaseOrderUpdate });

      if (!this.selectedProject.firstReceiptOfGoods && purchaseOrderUpdate.purchaseOrderUpdateStatus == 'Completed') {
        this.selectedProject.firstReceiptOfGoods = new Date();
        this.saveProject(this.selectedProject);
      }
    }
  }

  saveVendor(vendor: IVendor) {
    this.effectService.post({ name: Services.Vendor, data: vendor });
  }

  stubPickUpRequest() {
    var request: IScheduledPickUpRequest = {
      customerId: this.selectedCustomer.id,
      projectName: this.selectedProject.name,
      projectId: this.selectedProject.id,
      scheduled: false
    };

    return request;
  }

  saveScheduledPickUpRequest(scheduledPickUpRequest: IScheduledPickUpRequest) {
    this.effectService.post({ name: Services.ScheduledPickUpRequest, data: scheduledPickUpRequest });
  }

  stubUnclaimedFreightInqury() {
    var inqury: IUnclaimedFreightInqury = {
      projectId: this.selectedProject.id
    };

    var request: any = {
      projectName: this.selectedProject.name,
      scheduled: false,
      unclaimedFreightInqury: inqury
    };

    return request;
  }

  submitUnclaimedFreightInqury(unclaimedFreightInqury: IUnclaimedFreightInqury) {
    this.effectService.post({ name: Services.UnclaimedFreightInqury, data: unclaimedFreightInqury });
  }

  getTagText(tag: ITagSpan) {
    var text: string = "";

    if (tag.start == tag.end)
      text += "(" + tag.start + ") ";
    else
      text += "(" + tag.start + "-" + tag.end + ") ";

    text += tag.color + " Lot: " + tag.lot;

    return text;
  }

  updateSelectedProject() {
    this.selectedProjectChanging = true;
    this.projectDefaultTagColor = this.getDefaultTagColorForSelectedProject();
    this.projectDefaultTagLot = this.getDefaultTagLotForSelectedProject();

    this.effectService.getAllByProjectID({ name: Services.PurchaseOrder, data: this.selectedProject.id });
    this.effectService.getAllByProjectID({ name: Services.PurchaseOrderUpdate, data: this.selectedProject.id });
    this.effectService.getAllByProjectID({ name: Services.Piece, data: this.selectedProject.id });
    if (this.utilityService.currentUserRole() == 'Admin' || this.utilityService.currentUserRole() == 'Employee')
      this.effectService.getAllByProjectID({ name: Services.TagSpan, data: this.selectedProject.id });

    if (this.selectedProject)
      this.purchaseOrdersForProject = this.purchaseOrders.filter(x => x.projectId == this.selectedProject.id);
  }

  updatedeliveredOutDate(poId: number) {
    var po: IPurchaseOrder = this.purchaseOrdersForProject.filter(x => x.id == poId)[0];
    po.deliveredOutDate = new Date(po["deliveredOutDateDisplay"]["year"], po["deliveredOutDateDisplay"]["month"] - 1, po["deliveredOutDateDisplay"]["day"], 0, 0, 0, 0);

    this.savePo(poId, po.deliveredOutDate);
  }

  getDefaultTagColorForSelectedProject() {
    if (this.purchaseOrdersForProject.length > 0) {
      var pieces: IPiece[] = this.pieces.filter(p => this.purchaseOrdersForProject[0].id == p.purchaseOrderId);
      if (pieces.length > 0 && pieces[0].tag != undefined && pieces[0].tag.color != undefined)
        return pieces[0].tag.color;
    }
    return "";
  }

  getDefaultTagLotForSelectedProject() {
    if (this.purchaseOrdersForProject.length > 0) {
      var pieces: IPiece[] = this.pieces.filter(p => this.purchaseOrdersForProject[0].id == p.purchaseOrderId);
      if (pieces.length > 0 && pieces[0].tag != undefined && pieces[0].tag.color != undefined)
        return pieces[0].tag.lot;
    }
    return "";
  }

  downloadCSV() {
    let csv: string = "Vendor,PO#,Pieces,Notes,Updates\r\n";
    this.purchaseOrdersForProject.forEach(po => {
      let pieces: string = "";
      po.pieces.forEach(p => pieces += p.description + " and ");
      let updates: string = "";
      po.purchaseOrderUpdates.forEach(pou => updates += pou.purchaseOrderUpdateStatus + " - " + pou.notes + " - " + pou.EnteredDateTime + " and ");
      csv += this.vendors.filter(x => x.id == po.vendorId)[0].name + "," + po.poNumber + "," + pieces + "," + po.notes + "," + updates;
    });
  }

  print() {
    window.print();
  }

  getTags(poId: number): string {
    let tags: string = "";
    this.pieces.filter(p => p.purchaseOrderId == poId).forEach(p => {
      tags += p.tag + " and ";
    });

    return tags.substring(0, tags.length - 5);
  }

  private handleKeyboardEvents(event: KeyboardEvent): void {
    if (event.type == 'keydown') {
      this.keysPressed[event.key] = true;
    }

    else if (event.type == 'keyup') {
      delete this.keysPressed[event.key];
    }

    // If alt + p is pressed, new Vendor
    if (this.keysPressed['p'] && this.keysPressed['Alt']) {
      event.preventDefault();
      event.stopPropagation();
      if (this.selectedProject.id) {
        this.addPO();
      }
    }

    let keyPressed = event.key;
    // If alt + v is pressed, select all
    if (this.keysPressed['v'] && this.keysPressed['Alt']) {
      event.preventDefault();
      event.stopPropagation();
      let modalRef = this.modalService.open(VendorModalComponent);
      modalRef.result.then((result) => this.saveVendor(result));
    }
  }

  lastSaveValue: any = "";
  saveValue(value: any) {
    this.lastSaveValue = value;
  }

  onSorted(event: any) {
    this.purchaseOrdersForProject = this.purchaseOrdersForProject.sort((a, b) => { return this.utilityService.compare(a, b, event.sortColumn, event.sortDirection) });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
