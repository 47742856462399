import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie';

import { AuthService } from 'app-shared';
import { UnicornService } from 'app-services';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  public formMain: FormGroup;
  public waiting: boolean;
  public errorApi: IErrorApi;
  public errorLogin: boolean;
  public showPassword: boolean = false;
  public returnUrl: string;
  private cookie: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private cookies: CookieService,
    private unicornService: UnicornService
  ) {
  }

  public ngOnInit() {

    // Get the site cookie and if a username is present, set the default value of the login form to that
    this.cookie = this.cookies.getObject('udApp') || {};
    let hasLogin, isLogin;
    if (this.cookie.username) {
      hasLogin = true;
      isLogin = this.cookie.username;
    }

    //If a token is set, do not allow users to hit the login page and route them to the index page
    if (window.sessionStorage.token) {
      //this.router.navigate(['/']); // 2DO: Breaks automated testing, not essential but would be nice
    }

    window.clearTimeout(this.authService.sessionTimer); // When the page is loaded, clear any legacy timeouts
    this.authService.logOutModal = null; // Get rid of logout modal if it persists

    this.formMain = this.fb.group({ // <-- the parent FormGroup
      userName: [isLogin, [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      remember: [hasLogin]
    });

    // get return url from route parameters or default to '/'
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.returnUrl = 'inventory';
  }

  public onLogin() {
    this.waiting = true;
    this.errorApi = null;
    this.errorLogin = false;

    // If the remember checkbox was checked
    if (this.formMain.value.remember) {
      this.cookie.username = this.formMain.value.userName;
    } else {
      delete this.cookie.username;
    }
    this.cookies.putObject('udApp', this.cookie);

    this.unicornService.Authenticate.post(this.formMain.value).subscribe(
      (success) => {
        window.sessionStorage.token = success.token;

        this.unicornService.User.get(this.formMain.value.userName).subscribe(
          (user) => {
            window.sessionStorage.userName = user.userName;
            window.sessionStorage.customerId = user.customerId;
            window.sessionStorage.userRole = user.userRole;
                        
            if (user.resetPasswordOnNextLogin) {
              this.router.navigate(["resetPassword"]);
            }

          }
        );

        this.router.navigate([this.returnUrl]);
      },
      (error) => {
        this.errorLogin = true;
      },
      () => {
        this.waiting = false;
        this.errorApi = null;
        this.errorLogin = false;
      }
    );
  }
}
