import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UnicornService, UtilityService } from '../../services';

@Component({
  selector: 'resetPassword',
  templateUrl: './resetPassword.component.html'
})
export class ResetPasswordComponent implements OnInit {
  password: string = "";
  password1: string = "";
  password2: string = "";
  
  constructor(private unicornService: UnicornService, private router: Router, public utilityService: UtilityService) {
  }

  ngOnInit() {

  }

  reset() {
    if (this.password1 != this.password2 || this.password1.length < 6)
      return;

    var requst = {
      userName: this.utilityService.currentUserName(),
      password: this.password,
      newPassword: this.password1
    }
    this.unicornService.Authenticate.put(this.utilityService.currentUserName(), requst).subscribe(
      (success) => {
        this.router.navigate(['inventory']);
      },
      (error) => {
        if (error.status == '401')
          alert("Invalid Current Password");
        else
          alert(error);
      }
    );
  }
}
