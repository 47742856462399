import { Routes } from '@angular/router';
import {
  LayoutMainComponent,
  HomeComponent,
  NoContentComponent,
  LoginComponent,
  InventoryComponent,
  UnclaimedFreightComponent,
  ExtraStorageComponent,
  ScheduleComponent,
  UserManagementComponent,
  InstallerManagementComponent,
  GuideComponent,
  ResetPasswordComponent,
  RTSChargesComponent,
  InstallerTimeOffComponent
} from './containers';


import { AuthGuard } from './shared';

const titleSlug: string = ' | Unicorn Delivery'; // Append to page title

export const ROUTES: Routes = [
  // Routes without masterpage or that do not need to be authenticated need to go first
  { path: 'login', component: LoginComponent, data: { title: 'Please Log In' + titleSlug } },

  // Routes that use masterpage go here
  // canActivate with AuthGuard determines if this is an authenticated only route
  {
    path: '', component: LayoutMainComponent,
    children: [
      { path: '', component: HomeComponent, data: { title: 'Home' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'inventory', component: InventoryComponent, data: { title: 'Inventory' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'unclaimedFreight', component: UnclaimedFreightComponent, data: { title: 'Unclaimed Freight' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'extraStorage', component: ExtraStorageComponent, data: { title: 'Extra Storage' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'schedule', component: ScheduleComponent, data: { title: 'Schedule' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'userManagement', component: UserManagementComponent, data: { title: 'User Management' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'installerManagement', component: InstallerManagementComponent, data: { title: 'Installer Management' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'installerTimeOff', component: InstallerTimeOffComponent, data: { title: 'Installer Time Off' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'guide', component: GuideComponent, data: { title: 'Guide' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'resetPassword', component: ResetPasswordComponent, data: { title: 'Reset Password' + titleSlug }, canActivate: [AuthGuard], },
      { path: 'rtsCharges', component: RTSChargesComponent, data: { title: 'RTS Charges' + titleSlug }, canActivate: [AuthGuard], },
      { path: '**', component: NoContentComponent, data: { title: 'Page Not Found' + titleSlug }, canActivate: [AuthGuard], },
    ]
  },
];
