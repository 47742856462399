import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICustomer, IProject, IRootStore, IInstaller, IInstallerTimeOff, IHoliday } from '../../contracts/ud';
import { EffectsService } from "app-shared";
import { Store } from "@ngrx/store";
import { Observable, forkJoin } from "rxjs";
import { Services } from "src/app/shared";
import { Subscription, combineLatest } from "rxjs";
import { UtilityService } from 'app-services';

export interface IDaysOff {
    installerIds: number[]
    date: Date
    numberOfDaysOff: number;
    weekYear: string
}

@Component({
    selector: 'schedule',
    templateUrl: './schedule.component.html',
    styles: [`
    .table-bordered th, .table-bordered td {
      border: 1px solid #000000;
    }
    .white {
      background-color: #fffffa
    }

    .blue {
      background-color: #95b4d1
    }

    thead { display: block; }
  `]
})
export class ScheduleComponent implements OnInit, OnDestroy {
    public customers: ICustomer[] = [];
    customers$: Observable<ICustomer[]>;
    customerSub: Subscription;

    public projects: IProject[] = [];
    projects$: Observable<IProject[]>;

    public installers: IInstaller[] = [];
    installers$: Observable<IInstaller[]>;

    installersTimeOff: IInstallerTimeOff[] = [];
    installersTimeOff$: Observable<IInstallerTimeOff[]>;

    holidays: IHoliday[] = [];
    holidays$: Observable<IHoliday[]>;

    projectsAndInstallersSub: Subscription;

    public editDates: boolean = false;
    public showHistorical: boolean = false;

    Wday: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    constructor(private store: Store<IRootStore>, private effectService: EffectsService, public utilityService: UtilityService) {
        this.customers$ = this.store.select(state => state.mainState.customers);
        this.projects$ = this.store.select(s => s.mainState.projects);
        this.installers$ = this.store.select(s => s.mainState.installers);
        this.installersTimeOff$ = this.store.select(state => state.mainState.installerTimeOffs);
        this.holidays$ = this.store.select(state => state.mainState.holidays);
    }

    ngOnInit() {
        this.effectService.getAll({ name: Services.Customer });
        this.effectService.getAll({ name: Services.Installer });
        this.effectService.getAll({ name: Services.Project });
        this.effectService.getAll({ name: Services.InstallerTimeOff });
        this.effectService.getAll({ name: Services.Holiday });

        this.customerSub = this.customers$.subscribe((c) => { this.customers = c; });

        this.projectsAndInstallersSub = combineLatest(this.installers$, this.projects$, this.installersTimeOff$, this.holidays$).subscribe((value) => {
            if (value[0].length < 1 || value[1].length < 1 || !value[2] || value[2].length < 1 || value[3].length < 1)
                return;

            this.installers = value[0].sort((a, b) => { return this.utilityService.compare(a, b, 'firstName') });
            this.projects = value[1].filter(x => !x.isResidential && x.installDate);
            this.installersTimeOff = value[2];
            this.holidays = value[3];

            this.holidays.forEach(h => {
                h.date = new Date(h.date);
                h["weekYear"] = (String)(this.getWeek(h.date)) + h.date.getFullYear();
            });

            //Calculate days off across all installers and add to dates.
            let id: number = -1;
            this.installersTimeOff.forEach(ito => {
                if (!ito.endDate || !ito.startDate)
                    return;
                let days = Math.ceil(Math.abs(new Date(ito.endDate).getTime() - new Date(ito.startDate).getTime()) / (1000 * 3600 * 24));
                if (days == 0)
                    days = 1;
                let p: IProject = { name: "Time Off", location: "Off", isResidential: false };
                p["timeOff"] = true;
                p.installDate = ito.startDate;
                p.loadDate = ito.startDate;
                p.projectInstallers = [];
                p.id = id;
                p.installDays = days;
                p.numberOfInstallers = 0;
                p.projectInstallers.push({ firstItemId: id, secondItemId: ito.installerId, daysOnJob: days });
                p.numberOfInstallers++;
                this.projects.push(p);
                id--;
            });

            this.sortInstallDates();

            if (this.installers.length > 0 && this.projects.length > 0)
                this.reRunInstallerDays();
        });
    }

    trackById(index, item) {
        return item ? item.id : undefined;
    }

    // Returns the ISO week of the date.
    getWeek(d: Date) {
        var date = new Date(d.getTime());
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
            - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    sortInstallDates() {
        this.projects = this.projects.sort((a, b) => {
            if (new Date(a.installDate).getTime() === new Date(b.installDate).getTime()) {
                if (a["timeOff"])
                    return -1;
                else if (b["timeOff"])
                    return 1;
                else
                    return 0;
            }
            return new Date(a.installDate).getTime() - new Date(b.installDate).getTime();
        });
    }

    reRunInstallerDays() {
        this.projects.forEach(p => {
            this.setInstallersOnDays(p);
            this.setHoliday(p);
        });

        //Set first white
        this.projects[0]["weekDisplay"] = 'white';
        //Start on second Project
        for (var i = 1; i < this.projects.length; i++) {
            if (this.projects[i]["weekNumber"] == this.projects[i - 1]["weekNumber"])
                this.projects[i]["weekDisplay"] = this.projects[i - 1]["weekDisplay"]
            else {
                if (this.projects[i - 1]["weekDisplay"] == 'white')
                    this.projects[i]["weekDisplay"] = 'blue';
                else
                    this.projects[i]["weekDisplay"] = 'white';
            }
        }
    }

    setInstallersOnDays(project: IProject) {
        if (!project.installDate)
            return;

        project.installDate = new Date(project.installDate);
        project["installDateDisplay"] = {};
        project["installDateDisplay"]["year"] = new Date(project.installDate).getFullYear();
        project["installDateDisplay"]["month"] = new Date(project.installDate).getMonth() + 1;
        project["installDateDisplay"]["day"] = new Date(project.installDate).getDate();
        project.loadDate = new Date(project.loadDate);
        project["loadDateDisplay"] = {};
        project["loadDateDisplay"]["year"] = new Date(project.loadDate).getFullYear();
        project["loadDateDisplay"]["month"] = new Date(project.loadDate).getMonth() + 1;
        project["loadDateDisplay"]["day"] = new Date(project.loadDate).getDate();

        //Set historical
        if (project.installDays == undefined)
            project.installDays = 0;

        project["isHistorical"] = new Date(project.installDate.getTime() + (1000 * 60 * 60 * 24 * project.installDays)) <= new Date();


        //Clear
        this.Wday.forEach(day => project[day] = "");
        this.Wday.forEach(day => project[day + "count"] = 0);
        this.Wday.forEach(day => project[day + "Color"] = "");
        this.Wday.forEach(day => project[day + "TimeOff"] = "");

        let startDate = new Date(project.installDate);
        let dayIndex = startDate.getDay();

        if (project.installDate && startDate)
            project["weekNumber"] = this.getWeek(startDate);

        //This is so the first round of the loop starts on the correct day.
        startDate.setDate(startDate.getDate() - 1);

        if (project.projectInstallers && project.projectInstallers.length > 1)
            project.projectInstallers.sort((a, b) => { if (a > b) return 1; });
        if (project.projectInstallers && project.projectInstallers[0] && this.installers.filter(x => x.id == project.projectInstallers[0].secondItemId)[0])
            project["color"] = this.installers.filter(x => x.id == project.projectInstallers[0].secondItemId)[0].color

        for (var i = 0; i < project.installDays; i++) {
            project[this.Wday[dayIndex + i]] = startDate.setDate(startDate.getDate() + 1);
        }

        if (project.projectInstallers) {
            project.projectInstallers.forEach(installer => {
                for (var i = 0; i < installer.daysOnJob; i++) {
                    if (!project[this.Wday[dayIndex + i]])
                        project[this.Wday[dayIndex + i]] = "";
                    if (!project[this.Wday[dayIndex + i]])
                        project[this.Wday[dayIndex + i]] = startDate.setDate(startDate.getDate() + 1);
                    project[this.Wday[dayIndex + i] + "count"]++;
                    if (!project[this.Wday[dayIndex + i] + "Color"] || project[this.Wday[dayIndex + i] + "Color"] == "")
                        project[this.Wday[dayIndex + i] + "Color"] = project["color"];

                    //TimeOff
                    if (project["timeOff"] && this.installers.filter(inst => inst.id == installer.secondItemId)[0])
                        project[this.Wday[dayIndex + i] + "TimeOff"] += this.installers.filter(inst => inst.id == installer.secondItemId)[0].initials + ", ";
                }
            });

            //Remove trailing comma
            if (project["timeOff"])
                this.Wday.forEach(d => {
                    if (project[d + "TimeOff"].indexOf(", ", project[d + "TimeOff"].length - 2) !== -1)
                        project[d + "TimeOff"] = project[d + "TimeOff"].slice(0, -2);
                });
        }
    }

    setHoliday(project: IProject) {
        if (!project.installDate)
            return;
        let weekYear: string = (String)(this.getWeek(project.installDate)) + project.installDate.getFullYear();
        let holidays = this.holidays.filter(h => h["weekYear"] == weekYear);
        if (holidays.length > 0) {
            let day: string = this.Wday[holidays[0].date.getDay()];
            project[day + "Color"] = "Pink";
            project[day + "IsHoliday"] = true;
            project[day + "HolidayText"] = holidays[0].name;
        }
    }

    updateloadDate(projectId: number) {
        var project: IProject = this.getProjectById(projectId);
        project.loadDate = new Date(project["loadDateDisplay"]["year"], project["loadDateDisplay"]["month"] - 1, project["loadDateDisplay"]["day"], 0, 0, 0, 0);

        this.saveProject(projectId);
    }

    updateInstallDate(projectId: number) {
        var project: IProject = this.getProjectById(projectId);
        project.installDate = new Date(project["installDateDisplay"]["year"], project["installDateDisplay"]["month"] - 1, project["installDateDisplay"]["day"], 0, 0, 0, 0);

        this.saveProject(projectId);
        this.reRunInstallerDays();
        this.sortInstallDates();
    }

    saveSchedule() {
        this.effectService.saveAll({ name: Services.Project, data: this.projects });
    }

    saveProject(projectId: number) {
        var project: IProject = this.getProjectById(projectId);
        this.effectService.put({ name: Services.Project, id: project.id, data: project });
        this.reRunInstallerDays();
    }

    ngOnDestroy() {
        this.customerSub.unsubscribe();
        this.projectsAndInstallersSub.unsubscribe();
    }

    getProjectById(projectId: number): IProject {
        var projects: IProject[] = this.projects.filter(p => p.id == projectId)
        if (projects.length > 0)
            return projects[0];
        else
            return null;
    }
}
