import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { IScheduledPickUpRequest } from "src/app/contracts";

@Component({
  selector: 'viewPreviouslyScheduledPickUpRequest-modal-document',
  templateUrl: './viewPreviouslyScheduledPickUpRequest.modal.component.html'
})
export class ViewPreviouslyScheduledPickUpRequestComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public waiting: boolean = false;
  public error: IErrorApi;
  public data: IScheduledPickUpRequest = {};

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  submit() {
    this.activeModal.close(this.data);
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
