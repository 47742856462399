import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
//import { HttpClientBase } from 'app-shared';
import { LogoutModalComponent } from 'app-components';
import { EffectsService } from './stores/main/main.effects.service';

@Injectable()
export class AuthService {

  public modalDuration: number = 120; // 120 How long to show the modal window
  public sessionTimer: any = null; // Holds the logout session timer
  public logOutModal: NgbModalRef;

  constructor(
    //private http: HttpClientBase,
    private modalService: NgbModal,
    private router: Router,
    private effectServices: EffectsService
  ) {
  }

  public refreshToken(): void {

    //let url = this.http.webApiUrl + 'api/Service/refreshToken';

    //if (window.sessionStorage.token) {
    //  // Mock login
    //  url = this.http.webApiUrl + 'assets/mock-data/refreshtoken.json';
    //  this.http.get(url).subscribe(response => {
    //    window.sessionStorage.token = response.json().Token;
    //    this.setTimer(response.json().ExpirationSeconds);
    //    return response;
    //  });
    //}
  }

  private setTimer(ExpirationSeconds: number): void {
    clearTimeout(this.sessionTimer);
    this.sessionTimer = setTimeout(() => {
      this.launchLogoutModal();
    }, (ExpirationSeconds - this.modalDuration * 2) * 1000); 
  }

  private launchLogoutModal(): void {
    clearTimeout(this.sessionTimer);
    this.logOutModal = this.modalService.open(LogoutModalComponent, <any>{ size: 'md' });
    this.logOutModal.componentInstance.modalDuration = this.modalDuration;

    // When the modal is closed via log out button
    this.logOutModal.result.then((closeReason) => {
      this.logOut();
    }, (dismissReason) => {// When modal is dismissed
      if (dismissReason != 'norefresh') {
        this.refreshToken();
      }
    });
  }

  public logOut(): void {
    this.effectServices.clearStore();
    clearTimeout(this.sessionTimer);
    window.sessionStorage.clear();
    this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
  }
}
