import { IStoreData } from "src/app/contracts";
import { ActionReducer } from "@ngrx/store";
import { Action } from 'rxjs/internal/scheduler/Action';

export const StoreMainActions = {
  RESET_STATE: 'RESET_STATE',
  REFRESH_STATE: 'REFRESH_STATE',

  GET: 'GET',
  GET_ALL: 'GET_ALL',
  GET_ALL_BY_PROJECT: 'GET_ALL_BY_PROJECT',
  GET_ALL_BY_CUSTOMER: 'GET_ALL_BY_CUSTOMER',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
  SAVE_ALL: 'SAVE_ALL',

  //LOAD: 'LOAD',
  LOADED: 'LOADED',
};

export const Services = {
  Customer: "customer",
  Project: "project",
  PurchaseOrder: "purchaseOrder",
  PurchaseOrderUpdate: "purchaseOrderUpdate",
  TagSpan: "tagSpan",
  Vendor: "vendor",
  Authenticate: "authenticate",
  Installer: "installer",
  ProjectInstaller: "projectInstaller",
  User: "user",
  Piece: "piece",
  ScheduledPickUpRequest: "scheduledPickUpRequest",
  UnclaimedFreightInqury: "unclaimedFreightInqury",
  InstallerTimeOff: "installerTimeOff",
  Holiday: "holiday",
  RTSLineItem: "rtsLineItem",
  RTSMonthlyCharge: "rtsMonthlyCharge"
};

export const initialState: IStoreData = {
  users: [],
  customers: [],
  projects: [],
  purchaseOrders: [],
  purchaseOrderUpdates: [],
  tagSpans: [],
  vendors: [],
  installers: [],
  projectInstallers: [],
  pieces: [],
  scheduledPickUpRequests: [],
  unclaimedFreightInqurys: [],
  installerTimeOffs: [],
  holidays: [],
  rtsLineItems: [],
  rtsMonthlyCharges: [],
  token: "",
};

export interface ActionWithPayload<T> extends Action<T> {
  type: string
  payload: T
}

export interface IPayload {
  data: any
  name: string
}

export function StoreMainReducer(state = initialState, action: ActionWithPayload<IPayload>) {
  //console.log('STORE REDUCER:', action.type, action.payload);
  let newState;

  switch (action.type) {
    case StoreMainActions.RESET_STATE:
      return {
        users: [],
        customers: [],
        projects: [],
        purchaseOrders: [],
        purchaseOrderUpdates: [],
        tagSpans: [],
        vendors: [],
        installers: [],
        projectInstallers: [],
        pieces: [],
        scheduledPickUpRequests: [],
        unclaimedFreightInqurys: [],
        installerTimeOffs: [],
        holidays: [],
        rtsLineItems: [],
        rtsMonthlyCharges: [],
        token: "",
      };
    case StoreMainActions.REFRESH_STATE:
      return Object.assign({}, state);
    case StoreMainActions.GET_ALL + StoreMainActions.LOADED:
    case StoreMainActions.SAVE_ALL + StoreMainActions.LOADED:
      newState = ReloadArray(state, action.payload.data, action.payload.name);
      return newState;
    case StoreMainActions.PUT + StoreMainActions.LOADED:
      newState = UpdateInArray(state, action.payload.data, action.payload.name);
      return newState;
    case StoreMainActions.GET_ALL_BY_PROJECT + StoreMainActions.LOADED:
      newState = UpdateArrayInArray(state, action.payload.data, action.payload.name);
      return newState;
    case StoreMainActions.GET_ALL_BY_CUSTOMER + StoreMainActions.LOADED:
      newState = UpdateArrayInArray(state, action.payload.data, action.payload.name);
      return newState;
    case StoreMainActions.POST + StoreMainActions.LOADED:
      newState = AddToArray(state, action.payload.data, action.payload.name);
      return newState;
    case StoreMainActions.DELETE + StoreMainActions.LOADED:
      newState = DeleteFromArray(state, action.payload.data, action.payload.name);
      return newState;
    default:
      return Object.assign({}, state);
  }

  function ReloadArray(currentState: IStoreData, data: any, name: string): IStoreData {
    let newList = {};
    newList[name + 's'] = data;
    return Object.assign(currentState, newList);
  }

  function UpdateInArray(currentState: IStoreData, data: any, name: string): IStoreData {
    if (data == undefined || data == null)
      return currentState;

    let newList = {};
    var pos = currentState[name + 's'].map((x) => x.id).indexOf(data.id);
    currentState[name + 's'][pos] = data;
    newList[name + 's'] = currentState[name + ['s']];
    return Object.assign(currentState, newList);
  }

  function UpdateArrayInArray(currentState: IStoreData, data: any, name: string): IStoreData {
    if (data == undefined || data == null)
      return currentState;

    let newList = {};
    currentState[name + 's'] = data;
    newList[name + 's'] = currentState[name + ['s']];
    return Object.assign(currentState, newList);
  }

  function AddToArray(currentState: IStoreData, data: any, name: string): IStoreData {
    let newState = Object.assign(currentState, {});
    newState[name + 's'] = [...currentState[name + 's'], data];
    return newState;
  }

  function DeleteFromArray(currentState: IStoreData, data: any, name: string): IStoreData {
    let newList = {};
    newList[name + 's'] = currentState[name + 's'].filter(item => item.id != data);
    return Object.assign(currentState, newList);
  }
}
