import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { IRootStore, ICustomer, IPurchaseOrder, IProject, IRTSLineItem, IPiece } from '../../contracts/ud';
import { Services, EffectsService } from "src/app/shared";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Subscription } from "rxjs";
import { UtilityService } from '../../services';

@Component({
  selector: 'customerRTS',
  templateUrl: './customerRTS.component.html',
})
export class CustomerRTSComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectedCustomer: ICustomer = { id: 0 };
  @Input() projects: IProject[] = [];

  subscriptions: Subscription[] = [];

  rtsLineItems: IRTSLineItem[] = [];
  public rtsLineItems$: Observable<IRTSLineItem[]>;

  constructor(private store: Store<IRootStore>, private effectsService: EffectsService, public utilityService: UtilityService) {
    this.rtsLineItems$ = this.store.select(state => state.mainState.rtsLineItems);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedCustomer) {
      this.rtsLineItems = [];
      if (this.subscriptions.length < 1) {
        this.subscriptions.push(this.rtsLineItems$.subscribe(rts => {
          this.rtsLineItems = rts.filter(x => x.customerID == this.selectedCustomer.id).sort((a, b) => {
            return this.utilityService.compare(a, b, 'dateReceived')
          });

          this.rtsLineItems.forEach(x => {
            if (!x.dateReceived)
              return;

            x.dateReceived = new Date(x.dateReceived);
            x["dateReceivedDisplay"] = {};
            x["dateReceivedDisplay"]["year"] = new Date(x.dateReceived).getFullYear();
            x["dateReceivedDisplay"]["month"] = new Date(x.dateReceived).getMonth() + 1;
            x["dateReceivedDisplay"]["day"] = new Date(x.dateReceived).getDate();
          });
        }));
      }
      this.effectsService.getAllByCustomerID({ name: Services.RTSLineItem, data: this.selectedCustomer.id });
    }
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  onSorted(event: any) {
    this.rtsLineItems = this.rtsLineItems.sort((a, b) => { return this.utilityService.compare(a, b, event.sortColumn, event.sortDirection) });
  }

  updatedateRecieved(rtsId: number) {
    var rts: IRTSLineItem = this.rtsLineItems.filter(x => x.id == rtsId)[0];
    rts.dateReceived = new Date(rts["dateReceivedDisplay"]["year"], rts["dateReceivedDisplay"]["month"] - 1, rts["dateReceivedDisplay"]["day"], 0, 0, 0, 0);

    this.saveRts(rtsId, rts.dateReceived);
  }

  saveRts(rtsId: number, newValue: any) {
    if (newValue == this.lastSaveValue || newValue == undefined)
      return;
    this.effectsService.put({ name: Services.RTSLineItem, id: rtsId, data: this.rtsLineItems.filter(x => x.id == rtsId)[0] });
  }

  lastSaveValue: any = "";
  saveValue(value: any) {
    this.lastSaveValue = value;
  }

  addRts() {
    this.effectsService.post({ name: Services.RTSLineItem, data: { customerId: this.selectedCustomer.id, dateReceived: new Date() } });
  }

  deleteRTS(rtsId: number) {
    this.effectsService.delete({ name: Services.RTSLineItem, id: rtsId });
  }

  moveRTS(projectId: number, rtsId: number) {
    let rtsItem = this.rtsLineItems.filter(x => x.id == rtsId)[0];
    let sourceProject = this.projects.filter(x => x.id == rtsItem.sourceProjectID)[0];

    let newPoNumber = "";
    if (!sourceProject)
      newPoNumber = "RTS - No Source Project";
    else
      newPoNumber = "RTS - " + sourceProject.name;

    let piece: IPiece = { description: rtsItem.numbers };
    let pieces: IPiece[] = [piece];

    let po: IPurchaseOrder = {
      dateReceived: rtsItem.dateReceived,
      notes: rtsItem.description,
      poNumber: newPoNumber,
      projectId: projectId,
      vendorId: rtsItem.originalVendorID,
      pieces: pieces
    };

    this.effectsService.post({ name: Services.PurchaseOrder, data: po });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
