import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from "rxjs";
import { Subscription } from "rxjs";
import { IRTSMonthlyCharge } from '../../contracts/ud';
import { UtilityService, UnicornService } from '../../services';

@Component({
  selector: 'rtsCharges',
  templateUrl: './rtsCharges.component.html',
})
export class RTSChargesComponent implements OnInit, OnDestroy {
  public month: number = new Date().getMonth();
  public year: number = new Date().getFullYear();

  subscriptions: Subscription[] = [];

  rtsMonthlyCharges: IRTSMonthlyCharge[] = [];
  public rtsMonthlyCharges$: Observable<IRTSMonthlyCharge[]>;
  rtsMonthlyChargesSub: Subscription;

  public totalCharges: number = 0;

  constructor(private service: UnicornService, private utilityService: UtilityService) {
  }

  ngOnInit() {
    this.runForDate(new Date().getFullYear(), new Date().getMonth());
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  runForDate(year: number, month: number) {
    if (this.rtsMonthlyChargesSub)
      this.rtsMonthlyChargesSub.unsubscribe();
    let monthToUse: number = +(<number>month) + 1;

    this.rtsMonthlyCharges$ = this.service.RTSMonthlyCharge.GetChargesForEachCustomerForMonth(monthToUse, year);
    this.rtsMonthlyChargesSub = this.rtsMonthlyCharges$.subscribe(es => {
      this.rtsMonthlyCharges = es.sort((a, b) => { return this.utilityService.compare(a, b, 'customerName') });
      this.totalCharges = 0;
      this.runTotalCharges();
    });
  }

  runClick() {
    this.runForDate(this.year, this.month);
  }

  onSorted(event: any) {
    this.rtsMonthlyCharges = this.rtsMonthlyCharges.sort((a, b) => { return this.utilityService.compare(a, b, event.sortColumn, event.sortDirection) });
  }

  saveAll() {
    this.rtsMonthlyCharges.forEach(e => { e.savedDateTime = new Date(this.year, this.month, 1);});
    this.rtsMonthlyChargesSub = this.service.RTSMonthlyCharge.saveAll(this.rtsMonthlyCharges).subscribe(es => {
      this.rtsMonthlyCharges = es.sort((a, b) => { return this.utilityService.compare(a, b, 'customerName') });
      this.totalCharges = 0;
      this.runTotalCharges();
    });
  }

  runTotalCharges() {
    this.totalCharges = 0;
    this.rtsMonthlyCharges.forEach(e => { this.totalCharges += e.totalChargesForMonth; });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
