import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map } from "rxjs/operators";

import { UnicornService } from "src/app/services";
import { Store } from '@ngrx/store';
import { IRootStore } from '../../../contracts';
import { StoreMainActions, Services } from '../..';

@Injectable()
export class EffectsService {

  services: { [id: string]: any } = {};

  constructor(private unicornService: UnicornService, private store: Store<IRootStore>) {
    this.services[Services.Authenticate] = this.unicornService.Authenticate;
    this.services[Services.Customer] = this.unicornService.Customer;
    this.services[Services.Project] = this.unicornService.Project;
    this.services[Services.PurchaseOrder] = this.unicornService.PurchaseOrder;
    this.services[Services.PurchaseOrderUpdate] = this.unicornService.PurchaseOrderUpdate;
    this.services[Services.TagSpan] = this.unicornService.TagSpan;
    this.services[Services.Vendor] = this.unicornService.Vendor;
    this.services[Services.Installer] = this.unicornService.Installer;
    this.services[Services.ProjectInstaller] = this.unicornService.ProjectInstaller;
    this.services[Services.Piece] = this.unicornService.Piece;
    this.services[Services.User] = this.unicornService.User;
    this.services[Services.ScheduledPickUpRequest] = this.unicornService.ScheduledPickUpRequest;
    this.services[Services.UnclaimedFreightInqury] = this.unicornService.UnclaimedFreightInqury;
    this.services[Services.InstallerTimeOff] = this.unicornService.InstallerTimeOff;
    this.services[Services.Holiday] = this.unicornService.Holiday;
    this.services[Services.RTSLineItem] = this.unicornService.RTSLineItem;
    this.services[Services.RTSMonthlyCharge] = this.unicornService.RTSMonthlyCharge;
  }

  public clearStore(): void {
    this.store.dispatch({ type: StoreMainActions.RESET_STATE, payload: {} });
  }

  public getAll(payload): void {
    this.services[payload.name].getAll().pipe(
      map((result) => {
        //console.log(result);
        return result;
      }))
      .subscribe(
        result => this.store.dispatch({ type: StoreMainActions.GET_ALL + StoreMainActions.LOADED, payload: { data: result, name: payload.name } }),
        error => this.handleError(error, payload));
  }

  public getAllByProjectID(payload): void {
    this.services[payload.name].getAllByProject(payload.data).pipe(
      map((result) => {
        //console.log(result);
        return result;
      }))
      .subscribe(
        result => { this.store.dispatch({ type: StoreMainActions.GET_ALL_BY_PROJECT + StoreMainActions.LOADED, payload: { data: result, name: payload.name } }) },
        error => this.handleError(error, payload));
  }

  public getAllByCustomerID(payload): void {
    this.services[payload.name].getAllByCustomer(payload.data).pipe(
      map((result) => {
        //console.log(result);
        return result;
      }))
      .subscribe(
      result => { this.store.dispatch({ type: StoreMainActions.GET_ALL_BY_CUSTOMER + StoreMainActions.LOADED, payload: { data: result, name: payload.name } }) },
        error => this.handleError(error, payload));
  }

  public get(payload): void {
    this.services[payload.name].get(payload).pipe(
      map(result => {
        //console.log(result);
        return result;
      }))
      .subscribe(
        result => this.store.dispatch({ type: StoreMainActions.GET + StoreMainActions.LOADED, payload: { data: result, name: payload.name } }),
        error => this.handleError(error, payload));
  }

  public post(payload): void {
    this.services[payload.name].post(payload.data).pipe(
      map(result => {
        //console.log(result);
        return result;
      }))
      .subscribe(
        result => {
          if (result)
            this.store.dispatch({ type: StoreMainActions.POST + StoreMainActions.LOADED, payload: { data: result, name: payload.name } });
          else
            return of(payload);
        },
        error => this.handleError(error, payload));
  }

  public put(payload): void {
    this.services[payload.name].put(payload.id, payload.data).pipe(
      map(result => {
        //console.log(result);
        return result;
      }))
      .subscribe(
        result => {
          if (result)
            this.store.dispatch({ type: StoreMainActions.PUT + StoreMainActions.LOADED, payload: { data: payload.data, name: payload.name } });
          else
            return of(payload);
        },
        error => this.handleError(error, payload));
  }

  public delete(payload): void {
    this.services[payload.name].delete(payload.id).pipe(
      map(result => {
        //console.log(result);
        return result;
      }))
      .subscribe(
        result => {
            this.store.dispatch({ type: StoreMainActions.DELETE + StoreMainActions.LOADED, payload: { data: payload.id, name: payload.name } });
        },
        error => this.handleError(error, payload));
  }

  public saveAll(payload): void {
    this.services[payload.name].saveAll(payload.data).pipe(
      map(result => {
        //console.log(result);
        return result;
      }))
      .subscribe(
        result => this.store.dispatch({ type: StoreMainActions.SAVE_ALL + StoreMainActions.LOADED, payload: { data: result, name: payload.name } }),
        error => this.handleError(error, payload));
  }

  private handleError(error: any, payload: any) {
    console.log(payload, error);
    return of(error);
  }
}
