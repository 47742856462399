﻿import { Pipe, PipeTransform } from '@angular/core';

import { IPurchaseOrderUpdate } from '../../contracts/ud';

@Pipe({
    name: 'purchaseOrderUpdates',
    pure: false
})
export class PurchaseOrderUpdatesPipe implements PipeTransform {
    transform(allPurchaseOrderUpdates: IPurchaseOrderUpdate[], purchaseOrderId?: number) {
        return allPurchaseOrderUpdates.filter(purchaseOrderUpdate => purchaseOrderId == purchaseOrderUpdate.purchaseOrderId);
  }
}