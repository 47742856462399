import { Component, OnInit, OnDestroy, } from '@angular/core';
import { IRootStore, IInstaller, IInstallerTimeOff } from '../../contracts/ud';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Services, EffectsService } from "src/app/shared";
import { Subscription } from "rxjs";
import { UtilityService } from 'app-services';

@Component({
  selector: 'installerTimeOff',
  templateUrl: './installerTimeOff.component.html'
})
export class InstallerTimeOffComponent implements OnInit, OnDestroy {

  installers: IInstaller[] = [];
  installersTimeOff: IInstallerTimeOff[] = [];
  installers$: Observable<IInstaller[]>;
  installersTimeOff$: Observable<IInstallerTimeOff[]>;
  installersSub: Subscription;
  installersTimeOffSub: Subscription;
  timeOffTypes: string[] = ["Vacation", "Sick", "Off"];

  constructor(private store: Store<IRootStore>, private effectsServices: EffectsService, public utilityService: UtilityService) {
    this.installers$ = this.store.select(state => state.mainState.installers);
    this.installersTimeOff$ = this.store.select(state => state.mainState.installerTimeOffs);
  }

  ngOnInit() {
    this.effectsServices.getAll({ name: Services.Installer });
    this.effectsServices.getAll({ name: Services.InstallerTimeOff });

    this.installersSub = this.installers$.subscribe(i => { this.installers = i.sort((a, b) => { return this.utilityService.compare(a, b, 'firstName') }); });
    this.installersTimeOffSub = this.installersTimeOff$.subscribe(i => {
      this.installersTimeOff = i.sort((a, b) => { return this.utilityService.compare(a, b, 'startDate', 'desc') });
      this.installersTimeOff.forEach(ito => {
        ito.startDate = new Date(ito.startDate);
        ito["startDateDisplay"] = {};
        ito["startDateDisplay"]["year"] = new Date(ito.startDate).getFullYear();
        ito["startDateDisplay"]["month"] = new Date(ito.startDate).getMonth() + 1;
        ito["startDateDisplay"]["day"] = new Date(ito.startDate).getDate();

        ito.endDate = new Date(ito.endDate);
        ito["endDateDisplay"] = {};
        ito["endDateDisplay"]["year"] = new Date(ito.endDate).getFullYear();
        ito["endDateDisplay"]["month"] = new Date(ito.endDate).getMonth() + 1;
        ito["endDateDisplay"]["day"] = new Date(ito.endDate).getDate();
        this.setDaysOff(ito);
      });
    });
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  setDaysOff(ito: IInstallerTimeOff) {
    ito["daysOff"] = Math.ceil(Math.abs(ito.endDate.getTime() - ito.startDate.getTime()) / (1000 * 3600 * 24)) + 1;
  }

  ngOnDestroy() {
    this.installersSub.unsubscribe();
    this.installersTimeOffSub.unsubscribe();
  }

  delete(id: number) {
    this.effectsServices.delete({ name: Services.InstallerTimeOff, id: id });
  }

  add() {
    this.effectsServices.post({ name: Services.InstallerTimeOff, data: { startDate: new Date(), endDate: new Date() } });
  }

  save(timeOff: IInstallerTimeOff) {
    timeOff.startDate = new Date(timeOff["startDateDisplay"]["year"], timeOff["startDateDisplay"]["month"] - 1, timeOff["startDateDisplay"]["day"], 0, 0, 0, 0);
    timeOff.endDate = new Date(timeOff["endDateDisplay"]["year"], timeOff["endDateDisplay"]["month"] - 1, timeOff["endDateDisplay"]["day"], 0, 0, 0, 0);

    this.setDaysOff(timeOff);

    this.effectsServices.put({ name: Services.InstallerTimeOff, id: timeOff.id, data: timeOff });
  }

  onSortedInstallers(event: any) {
    this.installersTimeOff = this.installersTimeOff.sort((a, b) => { return this.utilityService.compare(a, b, event.sortColumn, event.sortDirection) });
  }
}
