import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { shareReplay, catchError } from "rxjs/operators";

export interface IRestful {
  getAll?(): Observable<any>
  get?(id: number | string): Observable<any>
  post?(data: any): Observable<any>
  put?(id: number | string, data: any): Observable<any>
  delete?(id: number | string): Observable<boolean>
  saveAll?(data: any): Observable<any>
  url?: string
}

@Injectable()
export class BaseCrud {

  constructor(private http: HttpClient, protected webApiUrl: string) { }

  private createAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
    if (window.sessionStorage.getItem('token'))
      headers = headers.append('Authorization', window.sessionStorage.getItem('token'));
    return headers;
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occured:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was : ${error.error.message}`);
    }

    return throwError(`An error has occured: ${error}`);
  }

  protected getAll<T>(url: string): Observable<T[]> {
    return this.http.get<T[]>(this.webApiUrl + url,
      { headers: this.createAuthorizationHeader(new HttpHeaders()) })
      .pipe(catchError(this.handleError)).pipe(shareReplay(1));
  }

  protected get<T>(url: string): Observable<T> {
    return this.http.get<T>(this.webApiUrl + url,
      { headers: this.createAuthorizationHeader(new HttpHeaders()) })
      .pipe(catchError(this.handleError)).pipe(shareReplay(1));
  }

  protected post<T>(url: string, data): Observable<T> {
    return this.http.post<T>(this.webApiUrl + url, data,
      { headers: this.createAuthorizationHeader(new HttpHeaders()) })
      .pipe(catchError(this.handleError)).pipe(shareReplay(1));
  }

  protected put<T>(url: string, data: T): Observable<T> {
    return this.http.put<T>(this.webApiUrl + url, data,
      { headers: this.createAuthorizationHeader(new HttpHeaders()) })
      .pipe(catchError(this.handleError)).pipe(shareReplay(1));
  }

  protected delete<T>(url: string | number): Observable<any> {
    return this.http.delete(this.webApiUrl + url,
      { headers: this.createAuthorizationHeader(new HttpHeaders()) })
      .pipe(catchError(this.handleError)).pipe(shareReplay(1));

    //{ headers: this.createAuthorizationHeader(new Headers()) })
    //.map(res => {
    //  if (res.ok)
    //    return true;
    //  else {
    //    console.error(res.status + " " + res.statusText + " " + res.text);
    //    alert(res.status + " " + res.statusText + " " + res.text);
    //    return false;
    //  }
    //}).catch(error => {
    //  console.warn('DELETE Error, handle 403 unauth errors here', error);
    //  if (error.status == 401 || error.status == 403) {
    //    error.errorMsg = 'Please log in ';
    //    return Observable.throw(error);
    //  }
    //  else {
    //    error.errorMsg = 'Unable to delete ' + url;
    //    alert("Please take a screen shot of this error and then ctrl+f5 to reload the page: " + error + error._body);
    //    console.error(error);
    //    return Observable.throw(error);
    //  }
    //});
  }
}
