import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

  constructor() { }

  public compare(a: any, b: any, prop: string, dir?: string) {
    if (a[prop] == undefined && b[prop] == undefined)
      return 0;
    if (a[prop] == undefined || b[prop] == undefined)
      return -1;

    try {
      if (dir === 'asc' || !dir) {
        if (a[prop].toLowerCase() < b[prop].toLowerCase())
          return -1;
        if (a[prop].toLowerCase() > b[prop].toLowerCase())
          return 1;
        return 0;
      }
      else if (dir === 'desc') {
        if (a[prop].toLowerCase() > b[prop].toLowerCase())
          return -1;
        if (a[prop].toLowerCase() < b[prop].toLowerCase())
          return 1;
        return 0;
      }
    } catch (e) {
      try {
        if (dir === 'asc' || !dir) {
          if (a[prop] < b[prop])
            return -1;
          if (a[prop] > b[prop])
            return 1;
          return 0;
        }
        else if (dir === 'desc') {
          if (a[prop] > b[prop])
            return -1;
          if (a[prop] < b[prop])
            return 1;
          return 0;
        }
      } catch (e) {
        console.log('a = ' + a + ' b = ' + b + ' prop = ' + prop + e);
        return 0;
      }
      return 0;
    }
  };

  public currentUserName(): string { return window.sessionStorage.userName; }
  public currentCustomerId(): string { return window.sessionStorage.customerId; }
  public currentUserRole(): string { return window.sessionStorage.userRole; }

  public userIsCustomer(): boolean {
    return this.currentUserRole() == 'Customer';
  }
  public userIsAdminOrEmployee(): boolean {
    return this.userIsAdmin() || this.currentUserRole() == 'Employee';
  }
  public userIsAdmin(): boolean {
    return this.currentUserRole() == 'Admin';
  }
}
