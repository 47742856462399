import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { IRootStore, IUser, ICustomer } from '../../contracts/ud';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { StoreMainActions, Services } from "src/app/shared";
import { CustomerProjectsPipe, ProjectPurchaseOrdersPipe, PieceTagSpansPipe, PurchaseOrderUpdatesPipe } from "app-shared";
import { Subscription } from "rxjs";

@Component({
  selector: 'guide',
  templateUrl: './guide.component.html'
})
export class GuideComponent implements OnInit {

  constructor() {
    
  }

  ngOnInit() {
  }
}
