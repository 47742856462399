import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IVendor } from "src/app/contracts";

@Component({
  selector: 'vendor-modal-document',
  templateUrl: './vendor.modal.component.html'
})
export class VendorModalComponent implements OnInit {

  public data: IVendor = {};

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  submit() {
    this.activeModal.close(this.data);
  }

  delete() {
    this.data["DELETE"] = true;
    this.activeModal.close(this.data);
  }
}
