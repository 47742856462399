﻿import { Pipe, PipeTransform } from '@angular/core';

import { IProject } from '../../contracts/ud';

@Pipe({
    name: 'customerProjects',
    pure: false
})
export class CustomerProjectsPipe implements PipeTransform {
    transform(allProjects: IProject[], customerId?: number) {
        return allProjects.filter(project => customerId == project.customerId);
  }
}