import { Pipe, PipeTransform } from '@angular/core';

import { IExtraStorage } from '../../contracts/ud';

@Pipe({
  name: 'showExtraStorage',
  pure: false
})
export class ShowExtraStoragePipe implements PipeTransform {
  transform(es: IExtraStorage[], showExtraStorage: boolean) {
    if (showExtraStorage)
      return es;
    else
      return es.filter(e => e.monthCharge > 0);
  }
}
