﻿import { Pipe, PipeTransform } from '@angular/core';

import { IPurchaseOrder } from '../../contracts/ud';

@Pipe({
    name: 'projectPurchaseOrders',
    pure: false
})
export class ProjectPurchaseOrdersPipe implements PipeTransform {
    transform(allPurchaseOrders: IPurchaseOrder[], projectId?: number) {
        return allPurchaseOrders.filter(purchaseOrder => projectId == purchaseOrder.projectId);
  }
}