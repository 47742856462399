import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPurchaseOrderUpdate } from "src/app/contracts";

@Component({
    selector: 'purchaseOrderUpdate-modal-document',
    templateUrl: './purchaseOrderUpdate.modal.component.html'
})
export class PurchaseOrderUpdateModalComponent implements OnInit {

    public data: IPurchaseOrderUpdate = {};
    
    constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

    submit() {
        this.activeModal.close(this.data);
    }

    delete() {
        this.data["DELETE"] = true;
        this.activeModal.close(this.data);
    }
}
