import { Component, OnInit,  ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { IRootStore, ICustomer, IProject, IPurchaseOrder, IVendor, IPurchaseOrderUpdate, ITagSpan, IPiece, IScheduledPickUpRequest, IUnclaimedFreightInqury } from '../../contracts/ud';
import { EffectsService } from "app-shared";
import { Services } from "src/app/shared";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Subscription } from "rxjs";
import { UtilityService } from 'app-services';

@Component({
  selector: 'unclaimedFreight',
  templateUrl: './unclaimedFreight.component.html'
  //, changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnclaimedFreightComponent implements OnInit, OnDestroy {
  public showInquiries: boolean = true;
  public ufiSetUp: boolean = false;

  subscriptions: Subscription[] = [];

  customers: ICustomer[] = [];
  projects: IProject[] = [];
  vendors: IVendor[] = [];
  purchaseOrders: IPurchaseOrder[] = [];
  purchaseOrderUpdates: IPurchaseOrderUpdate[] = [];
  pieces: IPiece[] = [];
  scheduledPickUpRequests: IScheduledPickUpRequest[] = [];
  unclaimedFreightInquiries: IUnclaimedFreightInqury[] = [];
  public customers$: Observable<ICustomer[]>;
  public projects$: Observable<IProject[]>;
  public vendors$: Observable<IPiece[]>;
  public purchaseOrders$: Observable<IPurchaseOrder[]>;
  public purchaseOrderUpdates$: Observable<IPurchaseOrderUpdate[]>;
  public pieces$: Observable<ITagSpan[]>;
  public unclaimedFreightInquiries$: Observable<IUnclaimedFreightInqury[]>;

  constructor(private store: Store<IRootStore>, private cdr: ChangeDetectorRef, private effectsService: EffectsService, public utilityService: UtilityService) {
    this.customers$ = this.store.select(state => state.mainState.customers);
    this.projects$ = this.store.select(state => state.mainState.projects);
    this.vendors$ = this.store.select(state => state.mainState.vendors);
    this.purchaseOrders$ = this.store.select(state => state.mainState.purchaseOrders);
    this.purchaseOrderUpdates$ = this.store.select(state => state.mainState.purchaseOrderUpdates);
    this.pieces$ = this.store.select(state => state.mainState.pieces);
    this.unclaimedFreightInquiries$ = this.store.select(state => state.mainState.unclaimedFreightInqurys);
  }

  ngOnInit() {
    this.effectsService.getAll({ name: Services.Customer });
    this.effectsService.getAll({ name: Services.Project });
    this.effectsService.getAll({ name: Services.UnclaimedFreightInqury });
    this.effectsService.getAll({ name: Services.Vendor });

    this.effectsService.getAllByProjectID({ name: Services.PurchaseOrder, data: '0' });
    this.effectsService.getAllByProjectID({ name: Services.PurchaseOrderUpdate, data: '0' });
    this.effectsService.getAllByProjectID({ name: Services.Piece, data: '0' });

    this.subscriptions.push(this.customers$.subscribe(customers => { this.customers = customers.sort((a, b) => { return this.utilityService.compare(a, b, 'name') }); this.checkAll(); }));
    this.subscriptions.push(this.projects$.subscribe(ps => { this.projects = ps.sort((a, b) => { return this.utilityService.compare(a, b, 'name') }); this.checkAll(); }));
    this.subscriptions.push(this.pieces$.subscribe(ps => { this.pieces = ps; this.checkAll(); }));
    this.subscriptions.push(this.vendors$.subscribe(vs => { this.vendors = vs.sort((a, b) => { return this.utilityService.compare(a, b, 'name') }); this.checkAll(); }));
    this.subscriptions.push(this.unclaimedFreightInquiries$.subscribe(ufi => {
      this.unclaimedFreightInquiries = ufi.filter(x => !x.resolved);
      this.checkAll();
    }));

    this.subscriptions.push(this.purchaseOrders$.subscribe(pos => {
      this.purchaseOrders = pos.filter(x => x.projectId == null);
      this.purchaseOrders.forEach(po => {
        if (po.dateReceived == null || po.dateReceived == undefined)
          po.dateReceived = new Date();
        po.dateReceived = new Date(po.dateReceived);
        po["dateReceivedDisplay"] = {};
        po["dateReceivedDisplay"]["year"] = new Date(po.dateReceived).getFullYear();
        po["dateReceivedDisplay"]["month"] = new Date(po.dateReceived).getMonth() + 1;
        po["dateReceivedDisplay"]["day"] = new Date(po.dateReceived).getDate();
      });
      this.checkAll();
    }));

    this.subscriptions.push(this.purchaseOrderUpdates$.subscribe(pous => {
      this.purchaseOrderUpdates = pous;
      this.purchaseOrderUpdates.forEach(u => {
        if (u.purchaseOrderUpdateStatus == 'Completed')
          u['fontColor'] = 'green';
        else if (u.purchaseOrderUpdateStatus == 'Damaged')
          u['fontColor'] = 'red';
        else if (u.purchaseOrderUpdateStatus == 'BackOrdered')
          u['fontColor'] = 'orange';
      });
      this.checkAll();
    }));
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  startTime: any
  endTime: any

  timerStart() {
    this.startTime = new Date();
  };

  timerEnd(msg) {
    this.endTime = new Date();
    var timeDiff = this.endTime - this.startTime; //in ms

    console.log(msg + " " + timeDiff + " ms");
    this.endTime = null;
    this.startTime = null;
  }

  checkAll() {
    if (this.unclaimedFreightInquiries && this.projects && this.customers && !this.ufiSetUp) {
      this.unclaimedFreightInquiries.forEach(ufi => {
        if (ufi['customerId'])
          ufi['customer'] = this.customers.filter(c => c.id == ufi['customerId'])[0].name;
        if (ufi.projectId)
          ufi['project'] = this.projects.filter(p => p.id == ufi.projectId)[0].name;
      });
      this.unclaimedFreightInquiries.sort((a, b) => { return this.utilityService.compare(a, b, 'customerId') });
      this.ufiSetUp = true;
    }

    if (this.purchaseOrders.length && this.vendors.length && this.projects.length && this.customers.length) {//&& this.purchaseOrderUpdates.length && this.unclaimedFreightInquiries.length && this.pieces.length{
      this.timerStart();
      this.purchaseOrders.forEach(po => {
        po.pieces = this.pieces.filter(p => p.purchaseOrderId == po.id);
        po.purchaseOrderUpdates = this.purchaseOrderUpdates.filter(pou => pou.purchaseOrderId == po.id);
        if (po.vendorId)
          po['vendorName'] = this.vendors.filter(v => v.id == po.vendorId)[0].name;
      });
      //this.purchaseOrders = this.sortReceivedDates();
      this.timerEnd('CheckAll computation took');
      this.timerStart();
      this.cdr.detectChanges();
      this.timerEnd('CheckAll detect changes took');
    }
  }

  public movePO(projectId: number, poId: number) {
    this.purchaseOrders.filter(x => x.id == poId)[0].projectId = projectId;
    this.effectsService.put({ name: Services.PurchaseOrder, id: poId, data: this.purchaseOrders.filter(x => x.id == poId)[0] });
  }

  public saveAllPurchaseOrders() {
    this.effectsService.saveAll({ name: Services.PurchaseOrder, data: this.purchaseOrders.filter(x => x.projectId == null) });
  }

  public addPO() {
    this.effectsService.post({ name: Services.PurchaseOrder, data: { projectId: null, dateReceived: new Date() } });
  }

  public savePo(poId: number, newValue: any) {
    if (newValue == this.lastSaveValue || newValue == undefined)
      return;

    this.effectsService.put({ name: Services.PurchaseOrder, id: poId, data: this.purchaseOrders.filter(x => x.id == poId)[0] });
  }

  public deletePO(poId: number) {
    this.effectsService.delete({ name: Services.PurchaseOrder, id: poId });
  }

  savePOUpdate(purchaseOrderUpdate: IPurchaseOrderUpdate, poId?: number) {
    if (purchaseOrderUpdate["DELETE"]) {
      this.effectsService.delete({ name: Services.PurchaseOrderUpdate, id: purchaseOrderUpdate.id });
    }
    else if (purchaseOrderUpdate.id > 0) {
      this.effectsService.put({ name: Services.PurchaseOrderUpdate, id: purchaseOrderUpdate.id, data: purchaseOrderUpdate });
    }
    else {
      purchaseOrderUpdate.purchaseOrderId = poId;
      this.effectsService.post({ name: Services.PurchaseOrderUpdate, data: purchaseOrderUpdate });
    }
  }

  saveVendor(vendor: IVendor) {
    this.effectsService.post({ name: Services.Vendor, data: vendor });
  }

  markResolved(unclaimedFreightInqury: IUnclaimedFreightInqury) {
    unclaimedFreightInqury.resolved = true;
    this.effectsService.put({ name: Services.UnclaimedFreightInqury, id: unclaimedFreightInqury.id, data: unclaimedFreightInqury });
  }

  updatedateReceived(poId: number) {
    var po: IPurchaseOrder = this.getPOById(poId);
    po.dateReceived = new Date(po["dateReceivedDisplay"]["year"], po["dateReceivedDisplay"]["month"] - 1, po["dateReceivedDisplay"]["day"], 0, 0, 0, 0);

    this.lastSaveValue = null;
    this.savePo(poId, po.dateReceived);
  }

  getPOById(projectId: number): IPurchaseOrder {
    var pos: IPurchaseOrder[] = this.purchaseOrders.filter(p => p.id == projectId)
    if (pos.length > 0)
      return pos[0];
    else
      return null;
  }

  sortReceivedDates() {
    return this.purchaseOrders.sort((a, b) => {
      return this.getTime(b.dateReceived) - this.getTime(a.dateReceived);
    });
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  lastSaveValue: any = "";
  saveValue(value: any) {
    this.lastSaveValue = value;
  }

  onSortedInquiries(event: any) {
    this.unclaimedFreightInquiries = this.unclaimedFreightInquiries.sort((a, b) => { return this.utilityService.compare(a, b, event.sortColumn, event.sortDirection) });
  }

  onSortedUnclaimedFrieght(event: any) {
    this.purchaseOrders = this.purchaseOrders.sort((a, b) => { return this.utilityService.compare(a, b, event.sortColumn, event.sortDirection) });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
