import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { IProject } from "src/app/contracts";

@Component({
  selector: 'project-modal-document',
  templateUrl: './project.modal.component.html'
})
export class ProjectModalComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public waiting: boolean = false;
  public error: IErrorApi;
  public data: IProject = {};

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.data.installDate == undefined)
      this.data["installDateDisplay"] = null;
    else {
      this.data.installDate = new Date(this.data.installDate);
      this.data["installDateDisplay"] = {};
      this.data["installDateDisplay"]["year"] = new Date(this.data.installDate).getFullYear();
      this.data["installDateDisplay"]["month"] = new Date(this.data.installDate).getMonth() + 1;
      this.data["installDateDisplay"]["day"] = new Date(this.data.installDate).getDate();
    }

    if (this.data.loadDate == undefined)
      this.data.loadDate = null;
    else {
      this.data.loadDate = new Date(this.data.loadDate);
      this.data["loadDateDisplay"] = {};
      this.data["loadDateDisplay"]["year"] = new Date(this.data.loadDate).getFullYear();
      this.data["loadDateDisplay"]["month"] = new Date(this.data.loadDate).getMonth() + 1;
      this.data["loadDateDisplay"]["day"] = new Date(this.data.loadDate).getDate();
    }

    if (this.data.firstReceiptOfGoods == undefined)
      this.data.firstReceiptOfGoods = null;
    else {
      this.data.firstReceiptOfGoods = new Date(this.data.firstReceiptOfGoods);
      this.data["firstReceiptOfGoodsDisplay"] = {};
      this.data["firstReceiptOfGoodsDisplay"]["year"] = new Date(this.data.firstReceiptOfGoods).getFullYear();
      this.data["firstReceiptOfGoodsDisplay"]["month"] = new Date(this.data.firstReceiptOfGoods).getMonth() + 1;
      this.data["firstReceiptOfGoodsDisplay"]["day"] = new Date(this.data.firstReceiptOfGoods).getDate();
    }
  }

  submit() {
    this.activeModal.close(this.data);
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateloadDate() {
    this.data.loadDate = new Date(this.data["loadDateDisplay"]["year"], this.data["loadDateDisplay"]["month"] - 1, this.data["loadDateDisplay"]["day"], 0, 0, 0, 0);
  }

  updateInstallDate() {
    this.data.installDate = new Date(this.data["installDateDisplay"]["year"], this.data["installDateDisplay"]["month"] - 1, this.data["installDateDisplay"]["day"], 0, 0, 0, 0);
  }

  updateFirstReceiptOfGoods() {
    if (this.data["firstReceiptOfGoodsDisplay"] == "")
      this.data.firstReceiptOfGoods = null;
    else
      this.data.firstReceiptOfGoods = new Date(this.data["firstReceiptOfGoodsDisplay"]["year"], this.data["firstReceiptOfGoodsDisplay"]["month"] - 1, this.data["firstReceiptOfGoodsDisplay"]["day"], 0, 0, 0, 0);
  }
}
