import { Pipe, PipeTransform } from '@angular/core';

import { IProject } from '../../contracts/ud';

@Pipe({
    name: 'idToProjectName',
    pure: false
})
export class IdToProjectNamePipe implements PipeTransform {
  transform(projectId: number, allProjects: IProject[]) {
        for (var i = 0; i < allProjects.length; i++) {
            if (allProjects[i].id == projectId)
                return allProjects[i].name;
        }
    }
}
