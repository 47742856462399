import { Component, OnInit, OnDestroy } from '@angular/core';
import { IRootStore, IInstaller } from '../../contracts/ud';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Services, EffectsService } from "src/app/shared";
import { Subscription } from "rxjs";
import { UtilityService } from 'app-services';

@Component({
  selector: 'installerManagement',
  templateUrl: './installerManagement.component.html'
})
export class InstallerManagementComponent implements OnInit, OnDestroy {

  installers: IInstaller[] = [];
  installers$: Observable<IInstaller[]>;
  installersSub: Subscription;

  constructor(private store: Store<IRootStore>, private effectsServices: EffectsService, public utilityService: UtilityService) {
    this.installers$ = this.store.select(state => state.mainState.installers);
  }

  ngOnInit() {
    this.effectsServices.getAll({ name: Services.Installer });

    this.installersSub = this.installers$.subscribe(i => { this.installers = i.sort((a, b) => { return this.utilityService.compare(a, b, 'firstName') }); });
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  ngOnDestroy() {
    this.installersSub.unsubscribe();
  }

  delete(id: number) {
    this.effectsServices.delete({ name: Services.Installer, id: id });
  }

  add() {
    this.installers.push({});
  }

  saveAll() {
    this.effectsServices.saveAll({ name: Services.Installer, data: this.installers });
  }

  onSortedInstallers(event: any) {
    this.installers = this.installers.sort((a, b) => { return this.utilityService.compare(a, b, event.sortColumn, event.sortDirection) });
  }
}
