import { Component, OnInit, OnDestroy } from '@angular/core';
import { IExtraStorage } from '../../contracts/ud';
import { Observable, Subscription } from "rxjs";
import { UnicornService, UtilityService } from "src/app/services";

@Component({
  selector: 'extraStorage',
  templateUrl: './extraStorage.component.html'
})
export class ExtraStorageComponent implements OnInit, OnDestroy {

  extraStorage: IExtraStorage[] = [];
  extraStorage$: Observable<IExtraStorage[]>;
  extraStorageSub: Subscription;

  public month: number = new Date().getMonth();
  public year: number = new Date().getFullYear();
  public totalCharges: number = 0;
  public showNoCharges: boolean = false;

  constructor(private service: UnicornService, private compareService: UtilityService) {
  }

  ngOnInit() {
    this.runForDate(new Date().getFullYear(), new Date().getMonth());
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  runClick() {
    this.runForDate(this.year, this.month);
  }

  runForDate(year: number, month: number) {
    if (this.extraStorageSub)
      this.extraStorageSub.unsubscribe();
    let monthToUse: number = +(<number>month) + 1;
    this.extraStorage$ = this.service.ExtraStorage.getCharges(monthToUse, year);
    this.extraStorageSub = this.extraStorage$.subscribe(es => {
      this.extraStorage = es.sort((a, b) => { return this.compareService.compare(a, b, 'customerName') });
      this.totalCharges = 0;
      es.forEach(e => { this.totalCharges += e.monthCharge; });
    });
  }

  compareFn(a: any, b: any, prop: string) {
    if (a[prop].toLowerCase() < b[prop].toLowerCase())
      return -1;
    if (a[prop].toLowerCase() > b[prop].toLowerCase())
      return 1;
    return 0;
  };

  ngOnDestroy() {
    this.extraStorageSub.unsubscribe();
  }
}
