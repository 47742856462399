import { Component, OnInit, OnDestroy } from '@angular/core';
import { IRootStore, IUser, ICustomer } from '../../contracts/ud';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Services, EffectsService } from "src/app/shared";
import { Subscription } from "rxjs";
import { UtilityService } from 'app-services';

@Component({
  selector: 'userManagement',
  templateUrl: './userManagement.component.html'
})
export class UserManagementComponent implements OnInit, OnDestroy {

  users: IUser[] = [];
  users$: Observable<IUser[]>;
  usersSub: Subscription;

  customers: ICustomer[] = [];
  customers$: Observable<ICustomer[]>;
  customersSub: Subscription;

  constructor(private store: Store<IRootStore>, private effectService: EffectsService, private compareService: UtilityService) {
    this.users$ = this.store.select(state => state.mainState.users);
    this.customers$ = this.store.select(state => state.mainState.customers);
  }

  ngOnInit() {
    this.effectService.getAll({ name: Services.User });
    this.effectService.getAll({ name: Services.Customer });

    this.usersSub = this.users$.subscribe(u => { this.users = u.sort((a, b) => { return this.compareService.compare(a, b, 'userName') }); this.setCustomerOnUser(); });
    this.customersSub = this.customers$.subscribe(c => {
      this.customers = c.sort((a, b) => { return this.compareService.compare(a, b, 'name') });
      this.setCustomerOnUser();
    });
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  setCustomerOnUser() {
    if (this.users.length && this.customers.length) {
      this.users.forEach(u => {
        if (u.customerId)
          u['customerName'] = this.customers.filter(c => c.id == u.customerId)[0].name;
      });
    }
  }

  ngOnDestroy() {
    this.usersSub.unsubscribe();
    this.customersSub.unsubscribe();
  }

  delete(id: number) {
    this.effectService.delete({ name: Services.User, id: id });
  }

  forceReset(id: number) {
    var user: IUser = this.users.filter(x => x.id == id)[0];
    user.resetPasswordOnNextLogin = true;
    this.effectService.put({ name: Services.User, id: id, data: user });
  }

  add() {
    this.users.push({});
  }

  saveAll() {
    this.effectService.saveAll({ name: Services.User, data: this.users });
  }

  onSortedUsers(event: any) {
    this.users = this.users.sort((a, b) => { return this.compareService.compare(a, b, event.sortColumn, event.sortDirection) });
  }
}
