import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseCrud } from './baseCrud.service';
import {
  ICustomer, IProject, IPurchaseOrder, IVendor, IPurchaseOrderUpdate, ITagSpan, IInstaller, IToken, IExtraStorage, IUser, IProjectInstaller, IEmailTemplate,
  IScheduledPickUpRequest, IUnclaimedFreightInqury, IInstallerTimeOff, IHoliday, IRTSLineItem, IRTSMonthlyCharge
} from '../contracts/ud';

@Injectable()
export class UnicornService extends BaseCrud {

  public Customer = this.createCRUDService<ICustomer>('customer');
  public Project = this.createCRUDServiceWithSaveAll<IProject>('project');
  public PurchaseOrder = this.createCRUDServiceWithSaveAllAndByProject<IPurchaseOrder>('purchaseOrder');
  public PurchaseOrderUpdate = this.createCRUDServiceWithByProject<IPurchaseOrderUpdate>('PurchaseOrderUpdate');
  public TagSpan = this.createCRUDServiceWithByProject<ITagSpan>('tagSpan');
  public Vendor = this.createCRUDService<IVendor>('vendor');
  public Authenticate = {
    put: (id: string, data: object) => this.put('authenticate/' + id, data),
    post: (data: object) => this.post<IToken>('authenticate', data),
  }
  public Installer = this.createCRUDServiceWithSaveAll<IInstaller>('installer');
  public Piece = this.createCRUDServiceWithSaveAllAndByProject<IInstaller>('piece');
  public User = this.createCRUDServiceWithSaveAll<IUser>('user');
  public EmailTemplate = this.createCRUDService<IEmailTemplate>('emailTemplate');
  public ScheduledPickUpRequest = this.createCRUDService<IScheduledPickUpRequest>('scheduledPickUpRequest');
  public UnclaimedFreightInqury = this.createCRUDService<IUnclaimedFreightInqury>('unclaimedFreightInqury');
  public ProjectInstaller = {
    getAll: () => this.getAll<IProjectInstaller>('projectInstaller'),
    put: (id: string, data: IProjectInstaller) => this.put('projectInstaller/', data),
    post: (data: IProjectInstaller) => this.post<IProjectInstaller>('projectInstaller', data),
    delete: (data: IProjectInstaller) => this.post<IProjectInstaller>('projectInstaller/Delete', data)
  }
  public ExtraStorage = {
    getCharges: (month: number, year: number) => this.getAll<IExtraStorage>('project/GetAllStorageChargesForMonth/' + month + '/' + year)
  }
  public InstallerTimeOff = this.createCRUDService<IInstallerTimeOff>('installerTimeOff');
  public Holiday = { getAll: () => this.getAll<IHoliday>('holiday') }
  public RTSLineItem = this.createCRUDServiceWithByCustomer<IRTSLineItem>('rtsLineItem');
  public RTSMonthlyCharge = {
    GetChargesForEachCustomerForMonth: (month: number, year: number) => this.getAll<IRTSMonthlyCharge>('RTSMonthlyCharge/GetChargesForEachCustomerForMonth/' + month + '/' + year),
    saveAll: (data: IRTSMonthlyCharge[]) => this.put('RTSMonthlyCharge/SaveAll', data)
  }

  constructor(private httpService: HttpClient) {
    //super(httpService, `http://localhost:38114/api/v1/`); //Dev local
    //super(httpService, `api/api/v1/`); //Prod
    super(httpService, (<any>window).webApiAddress);
  }

  build(obj: object, method: any, methodName: string) {
    obj[methodName] = method;
    return obj;
  }

  createCRUDService<T>(url: string) {
    return {
      getAll: () => this.getAll<T>(url),
      get: (id: string) => this.get<T>(url + '/' + id),
      put: (id: string, data: T) => this.put(url + '/' + id, data),
      post: (data: T) => this.post<T>(url, data),
      delete: (id: string) => this.delete(url + '/' + id)
    };
  }

  createCRUDServiceWithByProject<T>(url: string) {
    return {
      getAll: () => this.getAll<T>(url),
      get: (id: string) => this.get<T>(url + '/' + id),
      put: (id: string, data: T) => this.put(url + '/' + id, data),
      post: (data: T) => this.post<T>(url, data),
      delete: (id: string) => this.delete(url + '/' + id),
      getAllByProject: (projectId: string) => this.get<T>(url + '/ByProject/' + projectId)
    };
  }

  createCRUDServiceWithByCustomer<T>(url: string) {
    return {
      getAll: () => this.getAll<T>(url),
      get: (id: string) => this.get<T>(url + '/' + id),
      put: (id: string, data: T) => this.put(url + '/' + id, data),
      post: (data: T) => this.post<T>(url, data),
      delete: (id: string) => this.delete(url + '/' + id),
      getAllByCustomer: (customerId: string) => this.get<T>(url + '/ByCustomer/' + customerId)
    };
  }

  createCRUDServiceWithSaveAll<T>(url: string) {
    return {
      getAll: () => this.getAll<T>(url),
      get: (id: string) => this.get<T>(url + '/' + id),
      put: (id: string, data: T) => this.put(url + '/' + id, data),
      post: (data: T) => this.post<T>(url, data),
      delete: (id: string) => this.delete(url + '/' + id),
      saveAll: (data: T[]) => this.put(url + '/SaveAll', data)
    };
  }

  createCRUDServiceWithSaveAllAndByProject<T>(url: string) {
    return {
      getAll: () => this.getAll<T>(url),
      get: (id: string) => this.get<T>(url + '/' + id),
      put: (id: string, data: T) => this.put(url + '/' + id, data),
      post: (data: T) => this.post<T>(url, data),
      delete: (id: string) => this.delete(url + '/' + id),
      saveAll: (data: T[]) => this.put(url + '/SaveAll', data),
      getAllByProject: (projectId: string) => this.get<T>(url + '/ByProject/' + projectId)
    };
  }

  createCRUDServiceWithSaveAllAndByCustomer<T>(url: string) {
    return {
      getAll: () => this.getAll<T>(url),
      get: (id: string) => this.get<T>(url + '/' + id),
      put: (id: string, data: T) => this.put(url + '/' + id, data),
      post: (data: T) => this.post<T>(url, data),
      delete: (id: string) => this.delete(url + '/' + id),
      saveAll: (data: T[]) => this.put(url + '/SaveAll', data),
      getAllByCustomer: (customerId: string) => this.get<T>(url + '/ByCustomer/' + customerId)
    };
  }
}
