import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home',  // <home></home>
  styleUrls: [ './home.component.css' ],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

    constructor(
    ) {

    }

    public ngOnInit() {
    }
    
}
