import { Pipe, PipeTransform } from '@angular/core';

import { IPiece } from '../../contracts/ud';

@Pipe({
    name: 'purchaseOrderPieces',
    pure: false
})
export class PurchaseOrderPiecesPipe implements PipeTransform {
    transform(allPieces: IPiece[], purchaseOrderId?: number) {
        return allPieces.filter(p => purchaseOrderId == p.purchaseOrderId);
  }
}
