﻿import { Pipe, PipeTransform } from '@angular/core';

import { ICustomer } from '../../contracts/ud';

@Pipe({
    name: 'idToCustomerName',
    pure: false
})
export class IdToCustomerNamePipe implements PipeTransform {
    transform(customerId: number, allCustomers: ICustomer[]) {
        for (var i = 0; i < allCustomers.length; i++) {
            if (allCustomers[i].id == customerId)
                return allCustomers[i].name;
        }
    }
}