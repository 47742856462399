import { Pipe, PipeTransform } from '@angular/core';

import { IUnclaimedFreightInqury } from '../../contracts/ud';

@Pipe({
  name: 'showUnresolvedUnclaimedFreightInquiries',
  pure: false
})
export class ShowUnresolvedUnclaimedFreightInquiriesPipe implements PipeTransform {
  transform(ufi: IUnclaimedFreightInqury[]) {
    return ufi.filter(u => !u.resolved);
  }
}
