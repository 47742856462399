import { Pipe, PipeTransform } from '@angular/core';
import { IVendor, IPurchaseOrder } from '../../contracts/ud';
import { UtilityService } from '../../services';

@Pipe({
  name: 'sortPurchaseOrdersByVendor',
  pure: false
})
export class SortPurchaseOrdersByVendorPipe implements PipeTransform {
  constructor(private compareService: UtilityService) { }

  transform(pos: IPurchaseOrder[], allVendors: IVendor[]) {
    for (var i = 0; i < pos.length; i++) {
      pos[i]["vendorName"] = this.idToVendorName(pos[i].vendorId, allVendors);
    }
    return pos.sort((a, b) => { return this.compareService.compare(a, b, 'vendorName') });
  }

  idToVendorName(vendorId: number, allVendors: IVendor[]) {
    for (var i = 0; i < allVendors.length; i++) {
      if (allVendors[i].id == vendorId)
        return allVendors[i].name;
    }
  }
}
