import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'unclaimedFreightInqury-modal-document',
  templateUrl: './unclaimedFreightInqury.modal.component.html'
})
export class UnclaimedFreightInquryModalComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public waiting: boolean = false;
  public error: IErrorApi;
  public data: any = {};

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.data.unclaimedFreightInqury.dateReceived = new Date(this.data.unclaimedFreightInqury.dateReceived);
    this.data["dateReceivedDisplay"] = {};
    this.data["dateReceivedDisplay"]["year"] = new Date(this.data.unclaimedFreightInqury.dateReceived).getFullYear();
    this.data["dateReceivedDisplay"]["month"] = new Date(this.data.unclaimedFreightInqury.dateReceived).getMonth() + 1;
    this.data["dateReceivedDisplay"]["day"] = new Date(this.data.unclaimedFreightInqury.dateReceived).getDate();
  }

  submit() {
    this.activeModal.close(this.data.unclaimedFreightInqury);
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updatedateReceived() {
    if (!this.data.unclaimedFreightInqury.dateReceived)
      this.data.unclaimedFreightInqury.dateReceived = new Date();
    this.data.unclaimedFreightInqury.dateReceived.setFullYear(this.data["dateReceivedDisplay"]["year"]);
    this.data.unclaimedFreightInqury.dateReceived.setMonth(this.data["dateReceivedDisplay"]["month"] - 1);
    this.data.unclaimedFreightInqury.dateReceived.setDate(this.data["dateReceivedDisplay"]["day"]);
  }
}
