import { Pipe, PipeTransform } from '@angular/core';
import { IInstaller } from '../../contracts';

@Pipe({
    name: 'stringInstallerFromInstallers',
    pure: false
})
export class StringInstallerFromInstallersPipe implements PipeTransform {
    transform(id: number, allInstallers: IInstaller[]) {
        let installer: IInstaller[] = allInstallers.filter(x => x.id == id);
        if (installer && installer.length > 0)
            return installer[0].firstName + " " + installer[0].lastName + " (" + installer[0].initials + ")";
        else
            return "Error";
    }
}
