import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPiece } from "src/app/contracts";
import { Services, EffectsService } from "src/app/shared";

@Component({
  selector: 'piece-modal-document',
  templateUrl: './piece.modal.component.html'
})
export class PieceModalComponent implements OnInit {

  public data: { poId: number, pieces: IPiece[], previousTagColor: string, previousTagLot: string } = { poId: 0, pieces: [], previousTagColor: '', previousTagLot: '' };

  public piece: IPiece = { tag: {} };

  constructor(public activeModal: NgbActiveModal, private effectsServices: EffectsService) {
  }

  ngOnInit() {
    if (this.data.previousTagColor)
      this.piece.tag.color = this.data.previousTagColor;
    if (this.data.previousTagLot)
      this.piece.tag.lot = this.data.previousTagLot;
  }

  trackById(index, item) {
    return item ? item.id : undefined;
  }

  submit() {
    this.activeModal.close();
  }

  delete(id: number) {
    this.effectsServices.delete({ name: Services.Piece, id: id });
    this.data.pieces = this.data.pieces.filter(x => x.id != id);
  }

  addPiece() {
    if (this.piece.tag.start > -1 && this.piece.tag.end == null)
      this.piece.tag.end = this.piece.tag.start;

    if (this.piece.id > 0 && this.piece.tag.id > 0) {
      this.effectsServices.put({ name: Services.Piece, id: this.piece.id, data: this.piece });
    }
    else {
      this.piece.purchaseOrderId = this.data.poId;
      this.effectsServices.post({ name: Services.Piece, data: this.piece });
      this.data.pieces.push(this.piece);
    }

    this.piece = { tag: { color: this.piece.tag.color, lot: this.piece.tag.lot } };
  }
}
