import { Component,  OnInit,  ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AuthService } from './shared';

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title,
        private authService: AuthService,
    ) {}

    public ngOnInit() {
        // Perform actions on route change. 
        // Page titles are in app.routes.ts
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)).pipe(
            map(() => this.activatedRoute)).pipe(
            map(route => {
                while (route.firstChild) route = route.firstChild;
                return route;
            })).pipe(
            filter(route => route.outlet === 'primary')).pipe(
            mergeMap(route => route.data))
            .subscribe((event) => {
                this.title.setTitle(event['title']) // Change document title
                this.authService.refreshToken(); // On Route change, refresh authentication token
            });
    }
}
