import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared';

@Component({
  selector: 'nav-component',
  templateUrl: './nav.component.html'
})
export class NavComponent implements OnInit {

  public isOpen: boolean = false;

  constructor(private router: Router, public authService: AuthService) {
  }

  public ngOnInit() {
    //On route change, close nav window
    this.router.events.subscribe((val) => {
      this.isOpen = false;
    })
  }

  getUserName() {
    return window.sessionStorage.userName;
  }

  getUserRole() {
    return window.sessionStorage.userRole;
  }
}
