﻿import { Pipe, PipeTransform } from '@angular/core';

import { IInstaller, IProjectInstaller } from '../../contracts/ud';

@Pipe({
    name: 'formatInstallers',
    pure: false
})
export class FormatInstallersPipe implements PipeTransform {
    transform(projectInstallers: IProjectInstaller[], allInstallers: IInstaller[]) {
        if (!allInstallers || !projectInstallers)
            return "";
        let installerInitials = "";
        for (var i = 0; i < projectInstallers.length; i++) {
            let installer: IInstaller[] = allInstallers.filter(installer => installer.id == projectInstallers[i].secondItemId);
            if (installer && installer.length < 1)
                continue;
            installerInitials += installer[0].initials + ', ';
        }
        if (installerInitials.endsWith(', '))
            installerInitials = installerInitials.substring(0, installerInitials.length - 2);
        return installerInitials;
    }
}