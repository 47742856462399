import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'layout-main',
  templateUrl: './layout-main.component.html'
})
export class LayoutMainComponent implements OnInit {

    constructor(
    ) {
    }

    public ngOnInit() {
      
    }


}
