import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'layout-single',
    templateUrl: './layout-single.component.html'
})
export class LayoutSingleComponent implements OnInit {

    constructor(
    ) {
    }

    public ngOnInit() {
      
    }


}
